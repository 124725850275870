import { useContext, useState } from 'react';
import { InterviewData } from '../FionaTypes';
import {
  DispositionStatus,
  InterviewContext,
  InterviewStatus,
} from '../context/InterviewContext';

export interface AuthInput {
  code: string;
  donorLastName: string;
  donorDob: Date | string;
  collectionSite: string;
}

export interface ClinicAuthInput {
  authInput: AuthInput;
  token: string;
}

export type ValidateDonorData = { clinics: string; token: string };

export interface AuthCompleteResponse {
  interviewData: InterviewData;
  jwt: string;
}

export const useAuth = () => {
  const [auth, setAuth] = useState(false);
  const [lockedOut, setLockedOut] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const {
    setStatus,
    setSpecId,
    setLoading,
    setFirstName,
    setQuestions,
    setAnalyteNames,
    setRxExpiration,
    setDispositionId,
    setMroPhoneNumber,
    setElectronicInterviewID,
  } = useContext(InterviewContext);

  const storeInContext = (providedData: InterviewData) => {
    const {
      specId,
      analyteNames,
      rxExpiration,
      dispositionId,
      interviewStatus,
      interviewQuestions,
      mroInfo: { mroPhone },
      electronicInterviewId,
      donorInfo: { firstName },
    } = providedData;

    setSpecId(specId);
    setFirstName(firstName);
    setAnalyteNames(analyteNames);
    setQuestions(interviewQuestions);
    setRxExpiration(new Date(rxExpiration));
    setStatus(interviewStatus as InterviewStatus);
    setElectronicInterviewID(electronicInterviewId);
    setDispositionId(dispositionId as DispositionStatus);
    setLoading(false);
    setMroPhoneNumber(
      mroPhone
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
    );
  };

  /** Takes in Step 1 data for validation */
  const authenticateDonorInfo = async (data: AuthInput) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return fetch('/api/auth/donor', requestOptions).then((response) => {
      if (response.status === 403) {
        setLockedOut(true);
      }
      return response;
    });
  };

  /** Takes in step 2 code and clinicId and validates it on server */
  const authenticateClinic = async (data: ClinicAuthInput) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return fetch('/api/auth/clinic', requestOptions)
      .then(async (response) => {
        if (response.ok) {
          const responseData: AuthCompleteResponse = await response.json();
          storeInContext(responseData.interviewData);
          sessionStorage.setItem('jwt', responseData.jwt);
        } else if (response.status === 401) {
          setLockedOut(true);
          throw new Error('Session timed out');
        }

        return response;
      })
      .catch((e) => {
        throw new Error(e);
      });
  };

  return {
    auth,
    lockedOut,
    cancelled,
    authenticateClinic,
    authenticateDonorInfo,
  };
};
