import { Grid, Typography } from '@mui/material';
import { Context } from '../../context/AppContextBuilder';
import { useContext } from 'react';

export interface ThankYouOverturnedProps {
  name: string;
}

export const ThankYouOverturned = ({ name }: ThankYouOverturnedProps) => {
  const { nameTemplate, overturnedText } = useContext(Context);

  return (
    <Grid container spacing={3} justifyContent={'center'} zIndex={4}>
      <Grid item md={8} sm={7}>
        <Typography variant="h1" align="center">
          {nameTemplate(name)}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography
          align="center"
          variant="h5"
          fontWeight="normal"
          textTransform="none"
          component="h2"
        >
          {overturnedText}
        </Typography>
      </Grid>
    </Grid>
  );
};
