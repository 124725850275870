import { SxProps } from '@mui/material/styles';
import { Box, Typography, Stack, Grid } from '@mui/material';
import {
  AbbottLogo,
  InputButton,
  useResponsive,
} from '@abbott-components/core';
import { useContext } from 'react';
import { Context } from '../../../context/AppContextBuilder';
import { InterviewContext } from '../../../context/InterviewContext';

const LinkTextSx: SxProps = {
  fontWeight: 600,
  fontSize: '0.75rem',
  textDecoration: 'none',
};

const ButtonSX: SxProps = { padding: 0, minHeight: 0, minWidth: 0 };

const Footer = () => {
  const { md } = useResponsive();
  const { interviewCode } = useContext(InterviewContext);
  const { termsOfUse, contactUs, disclaimer, consumerHealthData } =
    useContext(Context);

  return (
    <Grid
      container
      data-testid="footer"
      sx={{ zIndex: 3, position: 'relative' }}
    >
      <div id="consent-banner"></div>
      <Grid item xs={12} md={4}>
        <Box
          data-testid="logoBox"
          sx={{
            margin: md ? '0 0 0 5rem' : '0 0 1.5rem 1rem',
            width: 120,
            height: 30,
          }}
        >
          <AbbottLogo variant="horizontal" color="blue-black" />
        </Box>
      </Grid>
      <Grid
        item
        md={8}
        xs={12}
        data-testid="buttonGrid"
        sx={
          md
            ? { display: 'flex', justifyContent: 'end', alignItems: 'end' }
            : {}
        }
      >
        <Stack
          direction={md ? 'row' : 'column'}
          sx={{ alignItems: 'start' }}
          spacing={2}
          ml={md ? 0 : 2}
          mr={md ? 10 : 0}
        >
          <InputButton
            variant="text"
            sx={ButtonSX}
            name="termsOfUse"
            onClick={() => {
              if (interviewCode.length > 0) {
                window.open(`/${interviewCode}/terms`, '_blank');
              } else {
                window.open('/terms', '_blank');
              }
            }}
          >
            <Typography sx={LinkTextSx} color="secondary.dark">
              {termsOfUse.toUpperCase()}
            </Typography>
          </InputButton>
          <InputButton variant="text" sx={ButtonSX} name="contactUs">
            <Typography
              component="a"
              sx={LinkTextSx}
              target="_blank"
              color="secondary.dark"
              href="https://www.escreen.com/us/en/home/contact.html"
            >
              {contactUs.toUpperCase()}
            </Typography>
          </InputButton>

          <InputButton variant="text" sx={ButtonSX} name="consumerHealthData">
            <Typography
              component="a"
              sx={LinkTextSx}
              target="_blank"
              color="secondary.dark"
              href="https://www.abbott.com/privacy-policy/consumer-health-data.html"
            >
              {consumerHealthData.toUpperCase()}
            </Typography>
          </InputButton>

          <Typography
            component="div"
            sx={{ ...LinkTextSx, textTransform: 'uppercase' }}
          >
            <div id="teconsent"></div>
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        data-testid="disclaimerGrid"
        sx={md ? { display: 'flex', justifyContent: 'end' } : {}}
      >
        <Box sx={{ margin: md ? '0 5rem 1rem' : '1rem' }}>
          <Typography sx={{ lineHeight: '1.125rem', fontSize: '0.8rem' }}>
            {disclaimer}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
