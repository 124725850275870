import Footer from '../footer/Footer';
import NavBar from '../nav-bar/NavBar';
import NavMenu from '../nav-menu/NavMenu';
import { useEffect, useState } from 'react';
import { Card, Box, Container } from '@mui/material';
import { Decoration } from '../decoration/Decoration';
import { MroLink } from '../../interview/mro-link/MroLink';
import { Show, useResponsive } from '@abbott-components/core';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { CanceledInterview } from '../../../pages/CanceledInterview';
import { AppPaths, PublicPaths } from '../../../routing/FionaRouteProvider';

export const Layout = () => {
  const { md } = useResponsive();
  const { pathname } = useLocation();
  const { interviewCode } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isOnNoCodeRoute =
    pathname.includes(PublicPaths.NO_CODE_FAQ) ||
    pathname.includes(PublicPaths.NO_CODE_TERMS) ||
    pathname.includes(PublicPaths.NO_CODE_CANCELED) ||
    pathname.includes(PublicPaths.NO_CODE_FAQ_ERROR);

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [pathname]);
  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <NavBar
        openMenu={() => {
          setIsMenuOpen(true);
        }}
      />
      <NavMenu
        isOpen={isMenuOpen}
        closeMenu={() => {
          setIsMenuOpen(false);
        }}
      />
      <Container
        sx={{
          minHeight: `calc(100vh - 128px - ${md ? '64px' : '226px'})`,
          '@media all': {
            maxWidth: '925px',
          },
          padding: 0,
        }}
      >
        <Decoration location={pathname as AppPaths} />
        <Card
          raised
          sx={{
            boxShadow: '0px 30px 80px #00000024',
            position: 'relative',
            zIndex: 2,
            borderRadius: '16px',
            marginBottom: '3.5rem',
          }}
        >
          <Box sx={{ margin: '2rem 1.5rem' }}>
            <Show
              fallback={<CanceledInterview />}
              when={
                (interviewCode?.length ?? 0) > 0 ||
                (isOnNoCodeRoute && !interviewCode)
              }
            >
              <Outlet />
            </Show>
          </Box>
        </Card>
        <MroLink />
      </Container>
      <Footer />
    </Box>
  );
};
