import { useContext, useEffect, useState } from 'react';
import { useAbbottToast } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';
import { ValidInterviewData } from '../Interview/Interview';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { IQuestion } from '../../components/interview/question/Question';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import { ThankYouTemplate } from '../../templates/ThankYouTemplate/ThankYouTemplate';
import { ThankYouOverturned } from '../../templates/ThankYouOverturned/ThankYouOverturned';
import { ThankYouReanalysis } from '../../templates/ThankYouReanalysis/ThankYouReanalysis';
import { ThankYouMissingUpload } from '../../templates/ThankYouMissingUpload/ThankYouMissingUpload';
import {
  DispositionStatus,
  InterviewContext,
  InterviewStatus,
} from '../../context/InterviewContext';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';

export const ThankYou = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { displayAbbottToast } = useAbbottToast();
  const {
    thankYou,
    emiPrefix,
    confirmed,
    overturned,
    sessionExpired,
    genericLoadError,
    thankYouFeedbackToast,
  } = useContext(Context);

  const {
    status,
    setStatus,
    firstName,
    rxExpiration,
    interviewCode,
    dispositionId,
    electronicInterviewID,
    setSpecId,
    setFirstName,
    setAnalyteNames,
    setRxExpiration,
    setQuestions,
    setElectronicInterviewID,
    setDispositionId,
    setMroPhoneNumber,
  } = useContext(InterviewContext);

  document.title = `${emiPrefix} ${thankYou}`;

  if ((data as { timedout: boolean }).timedout) {
    displayNetworkError(displayAbbottToast, sessionExpired);
    navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
  } else if ((data as { errorMessage: string }).errorMessage) {
    displayNetworkError(displayAbbottToast, genericLoadError);
    navigate(
      pathBuilder({ path: PublicPaths.UNAUTHORIZED, code: interviewCode }),
    );
  }

  useEffect(() => {
    const {
      specId,
      analyteNames,
      rxExpiration,
      dispositionId,
      interviewStatus,
      interviewQuestions,
      mroInfo: { mroPhone },
      electronicInterviewId,
      donorInfo: { firstName },
    } = data as ValidInterviewData;

    setSpecId(specId);
    setFirstName(firstName);
    setAnalyteNames(analyteNames);
    setRxExpiration(new Date(rxExpiration || ''));
    setStatus(interviewStatus as InterviewStatus);
    setQuestions(interviewQuestions as IQuestion[]);
    setElectronicInterviewID(electronicInterviewId);
    setDispositionId(dispositionId as DispositionStatus);
    // setLoading(false);
    if (mroPhone) {
      setMroPhoneNumber(
        mroPhone
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
      );
    }

    // Disabling the linter here because these are all set state functions which are identities and thus
    // do not need to be listed here as per the react docs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const updateRating = (value: number | null) => {
    if (value !== null && !loading) {
      setLoading(true);
      const ratingsData = {
        rate: value,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.jwt}`,
        },
        body: JSON.stringify(ratingsData),
      };

      fetch(
        `/api/rating/interview/${electronicInterviewID}/user-rating`,
        requestOptions,
      )
        .then((response) => {
          if (response.status === 401) {
            displayNetworkError(displayAbbottToast, sessionExpired);
            navigate(
              pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
            );
          } else {
            displayAbbottToast({
              type: 'success',
              message: thankYouFeedbackToast,
              position: 'top-center',
            });
          }
        })
        .catch((err) => console.error(err));

      setLoading(false);
    }
  };

  const handleReanalysis = () => {
    navigate(
      pathBuilder({ path: PrivatePaths.REANALYSIS, code: interviewCode }),
    );
  };

  const triggerUploadNow = () => {
    navigate(pathBuilder({ path: PrivatePaths.UPLOAD, code: interviewCode }));
  };

  const handleMissedDeadline = () => {
    setStatus(InterviewStatus.Complete);
  };

  if (status === InterviewStatus.Attachment) {
    return (
      <ThankYouMissingUpload
        uploadByDate={rxExpiration as Date}
        triggerUploadNow={triggerUploadNow}
        handleMissedDeadline={handleMissedDeadline}
      />
    );
  } else {
    //Null should be complete status (default case)
    switch (dispositionId) {
      case DispositionStatus.Negative:
        document.title = `${emiPrefix} ${overturned}`;
        return <ThankYouOverturned name={firstName} />;
      case DispositionStatus.Positive:
      case DispositionStatus.PositiveUTCD:
        document.title = `${emiPrefix} ${confirmed}`;
        return (
          <ThankYouReanalysis
            name={firstName}
            handleReanalysis={handleReanalysis}
          />
        );
      default:
        return (
          <ThankYouTemplate name={firstName} updateRating={updateRating} />
        );
    }
  }
};
