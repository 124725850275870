import { Box } from '@mui/material';
import { useResponsive } from '@abbott-components/core';
import { AnswerEntry } from '../../pages/Interview/Interview';
import {
  IQuestion,
  Question,
} from '../../components/interview/question/Question';

export interface InterviewTemplateProps {
  question: IQuestion;
  answers: AnswerEntry[];
  showsChildQuestion?: boolean;
  handleMultipleChoiceInput?: (
    value: string,
    isChildQuestion?: boolean,
  ) => void;
}

export const InterviewTemplate = ({
  question,
  answers,
  showsChildQuestion,
  handleMultipleChoiceInput,
}: InterviewTemplateProps) => {
  const { md } = useResponsive();
  return (
    <>
      <Question
        answers={answers}
        question={question}
        handleMultipleChoiceInput={handleMultipleChoiceInput}
      />
      {showsChildQuestion && question?.childQuestion ? (
        <>
          <Box p={md ? '2.45rem' : '0.5rem'} />
          <Question
            question={question?.childQuestion}
            answers={answers}
            handleMultipleChoiceInput={handleMultipleChoiceInput}
            isChildQuestion
          />
        </>
      ) : null}
    </>
  );
};
