export const strings = {
  // FOOTER
  contactUs: 'Contact Us',
  termsOfUse: 'Terms of Use',
  disclaimer: `© ${new Date().getUTCFullYear()} eScreen, Inc. All rights reserved. All trademarks referenced
  are trademarks of either the Abbott group of companies or their
  respective owners.`,
  consumerHealthData: 'Consumer Health Data Privacy Policy',

  // HOME
  labAnalysis: 'Lab Analysis',
  testComplete: 'Test Complete',
  medicalReview: 'Medical Review',
  gettingStarted: "Let's get started",
  collectSpecimen: 'Collect Specimen',
  greeting: "Hi! I'm Fiona, your virtual assistant",
  pendingMROReviewGreeting: 'Welcome Back!',
  completeInterviewGreeting: 'Medical Review Complete',
  drugTestgreeting: 'Looks like you recently took a drug test for',
  interviewExplanation:
    "Based upon the lab's analysis of your specimen we have a few follow-up questions we need to ask in order to finish processing your results.",
  mroTeamPendingReview:
    'The MRO team is reviewing the information provided and will follow up directly if there are any additional questions.',
  reviewComplete:
    'The MRO Team has reviewed the information you have provided.',

  // CANCELED
  hoursOfOperationHeading: 'Hours of operation:',
  faqErrorText: "Hmmm, something's not quite right...",
  pleaseWait: 'Please wait a few minutes and try again',
  hoursOfOperationText: 'Monday - Friday: 7am - 7pm CST',
  genericErrorText: 'We are unable to complete your medical review online.',
  callMROText:
    'If you have not already spoken to the medical review office, please call us at:',

  // NAV BUTTONS
  backButton: 'Back',

  // AUTHENTICATION
  sessionExpired: 'Your session expired. Please reverify.',

  // NAV MENU
  faq: 'FAQ',
  logoutText: 'LOGOUT',

  // BROWSER TAB TITLES (document.title)
  home: 'Home',
  welcome: 'Welcome',
  emiPrefix: 'eMI - ',
  confirmed: 'Confirmed',
  thankYou: 'Thank you!',
  overturned: 'Overturned',
  reanalysis: 'Reanalysis',
  verification: 'Verification',
  tryAgain: 'Please try again',
  interviewQuestion: 'Interview Question',
  canceledInterview: 'Canceled Interview',

  // MRO
  holdTimes: 'Callers may experience hold times',
  mroLink: "I'd like to talk with the MRO Office",
  mroRepresentative: 'To talk to an MRO representative, please call',

  // INTERVIEW QUESTION
  questionCounter: (currentQuestion: number, total: number) =>
    `Question ${currentQuestion} of ${total}`,

  // FAQ
  faqHeader: 'Frequently Asked Questions',
  faqText:
    "We're glad you're here! Below, you'll find answers to some of the most common questions people have about the medical review process",

  // REANLYSIS
  reanalysisHeader: 'Reanalysis Request',
  callReanalysis: 'To request a reanalysis please call us at:',
  reanalysisText:
    "A reanalysis is a retest of the donor's original specimen. The primary laboratory will package a small portion the original specimen, or the second half of the same specimen (in the case of split collection), and ship it to a secondary laboratory to be retested for any drugs or abnormal validity values found by the primary laboratory. Reanalysis must be requested within 72 hours of verifying final result. Additional fees may apply. Fees must be paid within 5 days of this request.",

  // WELCOME
  nextSteps: 'Next Steps',
  continueButton: 'Continue',
  specimenHeader: 'Specimen Results Received',
  medicalReviewRequired: 'Medical Review Required',
  labFound: 'The lab found the following in your specimen:',
  interviewIntroduction:
    'Answering the following questions will help the team make a determination.',
  evaluatedText:
    'The results will be evaluated along with any medical information provided.',

  // THANKYOU
  thankYouFeedbackToast: 'Thank you for your feedback!',
  nameTemplate: (name: string, shouldShowComma = true) =>
    `Hi${shouldShowComma ? ',' : ''} ${name}!`,
  thankYouText:
    "You've successfully answered the follow-up questions! The MRO team will review the information provided and will follow up directly if there are any additional questions.",

  // THANKYOU OVERTURNED
  overturnedText:
    'The documentation you provided has been reviewed and was sufficient to amend the result. If you have additional questions, please reach out to your employer.',

  // THANKYOU MISSING UPLOAD
  uploadNow: 'Upload Now',
  photoReminder: "Don't forget to upload your photos!",
  uploadHelpTitle: 'Upload Help',
  uploadHelpMessage:
    'Some of the selected files did not upload correctly. Uploaded files must be:',
  uploadHelpAction:
    'Note: You can choose to continue with any images that uploaded successfully by clicking "Submit"',
  timeRemainingText:
    'To qualify as a medically valid reason for this result, your photos must be uploaded within',

  // THANKYOU REANALYSIS
  orderReanalysisButtonText: 'Order Reanalysis',
  reviewedText1: 'Our team has reviewed your response and the',
  reviewedText2: 'information supplied was not sufficient to amend the result.',
  reviewedText3:
    'If you have additional questions, please contact your employer.',
  reanalysisOptionText:
    'You have the option to order a reanalysis. Click the link below for additional information.',

  // UPLOAD
  uploadTitle: 'Upload',
  yourName: 'Your Name',
  dateFilled: 'Date Filled',
  medName: 'Medication Name',
  submitButtonText: 'Submit',
  uploadFailedHelp: 'Need Help?',
  uploadButtonLabel: 'Upload Images',
  prescriptionNumber: 'Prescription Number',
  uploadSkip: 'Skip for now and upload later',
  uploadTechnicalRequirements: '20 mb or less',
  uploadTechnicalRequirements2: 'In a supported file type (PDF, PNG, JPG/JPEG)',
  prescriptionNotAvailable: "Don't have your prescription handy?",
  uploadImagesCallout: 'Upload images for each of the following:',
  rxCriteria: 'A valid prescription upload must clearly show the following:',
  genericLoadError:
    "We're sorry, there appears to have been an error loading the interview.",
  saveError:
    "We're having trouble saving the information you provided. Please try again.",
  genericUploadError:
    'Encountered an error while uploading your files. Please try again.',

  // VERIFICAITON
  nextButton: 'Next',
  confirmText: 'Confirm',
  verifyLastName: 'Verify your last name',
  dobPickerLabel: 'Verify your date of birth',
  makeSureYouHeader: "Let's make sure it's you.",
  whereDidTakeTest: 'Where did you go to take your drug test?',
  idNotFound:
    'The information provided does not match our records. Please check your entries and try again.',
  templateLabel:
    'Please provide images of your prescriptions/medical documentation.',
  step2VerificationSubheader:
    'Please select an answer below to finish verifying your identity.',
  step1VerificationSubheader:
    'Please complete the fields below to begin verifying your identity.',

  // TERMS
  privacyHeader: 'Privacy.',
  ownershipHeader: 'Ownership.',
  terminationHeader: 'Termination.',
  userContentHeader: 'User Content.',
  textMessageHeader: 'Text Messages.',
  prohibitionsHeader: 'Prohibitions.',
  appOverview: 'Application Overview.',
  acceptableUseHeader: 'Acceptable Use.',
  indemnificationHeader: 'Indemnification.',
  termsAndConditions: 'Terms and Conditions',
  loginCredentialsHeader: 'Login Credentials.',
  bindingAgreementHeader: 'Binding Agreement.',
  lawsAndRegulationsHeader: 'Laws and Regulations.',
  yourRepresentationsHeader: 'Your Representations.',
  limitationOfLiabilityHeader: 'LIMITATION OF LIABILITY.',
  changesServiceTermsHeader: 'Changes to Service and Terms.',
  copyrightsAndTrademarksHeader: 'Copyrights and Trademarks.',
  confidentialityAndNonUseHeader: 'Confidentiality and Non-Use.',
  potentialDisruptionOfServiceHeader: 'Potential Disruption of Service.',
  incorporationHeader: 'Incorporation, Enforceability and Governing Law.',
  introduction:
    'Please read these Terms of Use ("Terms") of eScreen, Inc. ("eScreen") carefully before downloading, accessing, or using this application. eScreen has installed what is known as "Security 128 Bit Encryption" on this application to limit access to only those individuals who are authorized to use it.',
  bindingAgreement:
    'BY ACCESSING OR USING THIS APPLICATION IN ANY MANNER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO BE BOUND BY THESE TERMS OF USE. If you do not agree to all of the Terms of Use, you are not authorized to access or otherwise use this application (the "Application").',
  authorizationStatement:
    'This Site is available only to users that have received authorization to use it or have a valid registered Username and Password and are employees of an authorized company. If you have not received authorization to use it or if you do not have a valid registered Username and Password or are no longer an employee of an authorized company, you are not authorized to use or access this Application.',
  termsUnderlyingAgreementHeader:
    'Terms of the Underlying Software License Agreement.',
  termsUnderlyingAgreement:
    'You are authorized to use this Application only as at the direction of your employer or eScreen.',
  textMessageAgreement:
    'Message and data rates may apply to text messages sent from this site for providing ePassport information.',
  bindingAgreementText:
    'These Terms (as amended from time to time by eScreen) form a binding agreement between you and eScreen. Your access to or use of the Application or eScreen services indicates your acceptance of these Terms as part of that agreement. You are agreeing to use the Application at your own risk.',
  loginCredentialsText:
    "In order to access and use this Application, you must provide appropriate login credentials. You agree to take any and all actions necessary to maintain the privacy of your login credentials, including, without limitation, safeguarding those credentials, adopting special precautions when accessing the Application from a device that is available for use by other users and exiting from the Application at the end of each session. In the event that you have reason to believe that a third party has unauthorized access to the login credentials, you should notify your company's administrative user.",
  prohibitionsText:
    'No identifying marks, intellectual property or other proprietary right notices of eScreen or other parties may be deleted from, altered or obscured on the Application. You may not translate, decompile, disassemble, reverse compile, reverse engineer, decrypt or otherwise seek to recreate the source code of the Application, adapt the Application in any way or use it to create a derivative work, or grant any other person or entity the right or access to do so, without the prior written approval of eScreen. You agree not to collect or harvest any personally identifiable information from the Application or eScreen, nor to use any communications systems provided by the Application for any commercial solicitation.',
  acceptableUseText:
    'You assume sole and complete responsibility for ensuring that your Username and Password or other login credentials are not used by anyone other than you. Unauthorized use includes, without limitation: (a) use of the Application to access web sites other than the Application; (b) attempts to gain access to data about individuals other than for legitimate screening purposes; (c) alteration or modification of individual data or of any Application configuration values from the originally delivered values; (d) accessing the Application through any technology or means other than through the user account information provided to you by eScreen; and (e) any use of the Application or the information contained therein in violation of any applicable law or regulation. You will not: (i) enter or transmit any information using the Application that is unlawful, false, offensive, defamatory, or infringes the rights of any person; (ii) use the Application in a way that disrupts use of the Application by others; and (iii) use any information obtained through the Application except for the purpose for which the information was provided, and must not attempt to use the Application to gain unauthorized access to information.',
  userContentText:
    'You shall be solely responsible for the posting or provision of any information, photos, video, text, graphics, music, sounds or other material input into the Application under your account (collectively, the "User Content"). In connection with the User Content, you affirm, represent and warrant that you or your employer (which includes any entity you are acting on behalf of, if applicable) owns or has the necessary licenses, rights, consents and permissions to use and authorize eScreen to use all proprietary rights in and to any and all User Content to enable inclusion and use of the User Content in the manner contemplated by the Application. You or your employer retain all ownership rights in User Content. By posting or providing User Content to eScreen, you (on behalf of yourself or your employer) hereby grant eScreen a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sublicenseable and transferable license to use, reproduce, distribute, modify, edit, adapt, publish, translate, incorporate, prepare derivative and collective works utilizing, display and perform the User Content in any form, medium or technology now known or later developed throughout the universe, for the improvement of the Application or use with eScreen\'s other products and services (as may exist now or in the future) and for such other lawful purposes as eScreen sees fit. In addition, you hereby waive (or warrant that the owner of such User Content has expressly waived) any and all "moral rights" in the User Content as to any of the foregoing rights and licenses granted to eScreen.',
  lawsAndRegulationsText:
    'Access to and use of this Application is subject to all applicable international, federal, state and local laws and regulations. You agree to abide by these laws and not use the Application in any way that violates such laws or regulations.',
  confidentialityAndNonUseText:
    'You will not disclose any Confidential Information to anyone who is not authorized to receive such information. You will protect eScreen\'s Confidential Information from unauthorized disclosure by taking at least those steps that you and your employer use to protect your own Confidential Information, but in no event less than reasonable efforts. You will not use eScreen\'s Confidential Information except for purposes of fulfilling your obligations under these Terms. "Confidential Information" means: (a) any information related to eScreen or the Application, including, but not limited to, eScreen\'s existing or proposed business, technology, products, services, internal structure and strategies, specifically including, without limitation, documentation, end-user materials, intellectual property, proposals, designs, concepts, methodologies, inventions, source or object code, developments, research, programs, databases, referral sources, customers, prospective customers, inventions, developments, "know-how," procedures, financial information or licensing and access policies.',
  privacyText:
    'Any personally identifiable information about an employee or donor that is provided through and accessible via the Application is subject to these Terms and the eScreen Privacy Policy. Any personally identifiable information or Personal Health Information (PHI) about any employee or donor that is provided by the employee, donor, client, their potential employer or employer, or a Third Party Administrator is subject to these Terms and the eScreen Privacy Policy.',
  ownershipText:
    'Access to the Application is licensed and not sold. You shall not, by virtue of these Terms or otherwise, acquire any rights whatsoever in the Application aside from the limited licenses granted herein, and you hereby expressly disclaim any other rights therein. eScreen shall have and retain all right, title and interest in and to the Application and all eScreen intellectual property rights comprising the Application as well as any modifications or enhancements made thereto, with the sole exception of any data provided by You. You hereby irrevocably assign to eScreen ownership of any and all suggestions, modifications, enhancements, improvements, alterations, changes or revisions to the Application (collectively, "Modifications") made or suggested by you, if any.',
  copyrightsAndTrademarksText:
    'The Application is the sole and exclusive property of eScreen, and is protected by copyright, trademark, and other intellectual property laws. Users may not modify, copy, distribute, transmit, display, publish, sell, license, create derivative works or otherwise use the Application for commercial or public purposes. Users may not use the trademarks, logos and service marks ("Marks") found on the Application for any purpose, including, but not limited to, use as "hot links" or meta tags in other pages or sites on the World Wide Web, without the written permission of eScreen.',
  potentialDisruptionOfServiceText:
    'Access to the Application may from time to time be unavailable, delayed, limited or slowed due to, among other things: Hardware failure, including among other things failures of computers (including your own computer), servers, networks, telecommunication lines and connections, and other electronic and mechanical equipment; Application failure, including among other things, bugs, errors, viruses, configuration problems, incompatibility of systems, utilities or applications, the operation of firewalls or screening programs, unreadable codes, or irregularities within particular documents or other content; Overload of system capacities; Damage caused by severe weather, earthquakes, wars, insurrection, riots, civil commotion, act of God, accident, fire, water damage, explosion, mechanical breakdown or natural disasters; Interruption (whether partial or total) of power supplies or other utility of service; Strike or other stoppage (whether partial or total) of labor; Governmental or regulatory restrictions, exchange rulings, court or tribunal orders or other human intervention; Any other cause (whether similar or dissimilar to any of the foregoing) whatsoever beyond the control of eScreen.',
  yourRepresentationsText:
    'You represent, warrant and covenant that: (a) you are authorized to access and use the Application; (b) you are authorized to consent and agree to the Terms on this Application and that references to "you" and "user" in the Terms include the entity you are authorized to act on behalf of, if applicable; (c) all instructions and information you enter into the Application related to the services will be accurate and complete; (d) you will verify and remain solely responsible for all instructions and information entered into the Application related to the services; (e) you will not upload, transmit, distribute or otherwise publish through the Application any materials that contain a virus, worm or other harmful component or that would cause the Application to malfunction; and (f) you will not decompile, reverse engineer, disassemble or unlawfully use or reproduce the Application or other copyrighted or trademarked material, trade secrets or other proprietary information contained in the Application.',
  limitationOfLiabilityText:
    'TO THE EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL ESCREEN OR ANY RELATED OR AFFILIATED ENTITIES BE LIABLE FOR ANY DAMAGES SUFFERED BY YOU, INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLERY OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, (I) LOSSES ARISING FROM THE USE OR THE INABILITY TO USE THE APPLICATION (INCLUDING THE SERVICES), (II) LOSSES ARISING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, (III) LOSS OF INFORMATION, PROGRAMS OR OTHER DATA THAT RESULT FROM ACCESS TO, USE OF, OR INABILITY TO USE THE APPLICATION (INCLUDING THE SERVICES) OR DUE TO ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION THROUGH THE INTERNET, [OR (IV) ANY DECISION MADE OR ACTIONS TAKEN BY USER BASED ON INFORMATION OR TEST RESULTS PROVIDED BY ESCREEN ON THIS SITE]. THIS LIMITATION OF LIABILITY SHALL BE IN FULL FORCE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED ON BREACH OF   CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, AND EVEN IF ESCREEN HAD PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
  indeminificationText:
    "As a condition of use of this Application, the User agrees to indemnify eScreen, its affiliates, and the directors and employees or any of them from and against any and all claims, demands, judgments, costs, liabilities, expenses (including attorneys' fees) and damages arising out of legal claims resulting from User's use of this Application and eScreen services, including, without limitation, any claims alleging facts that if true would constitute a breach by User of these Terms.",
  terminationText:
    'Your license to use and access the Application will terminate immediately upon termination or expiration of the authority granted by your employer or eScreen. In addition, your license to use and access the Application shall terminate immediately and without action from eScreen in the event (a) you breach any obligation included in these Terms; (b) eScreen changes any terms and conditions that will govern use of or access to the Application and you do not accept the additional terms and conditions; or (d) eScreen ceases providing access to the Application. Upon the termination or expiration of these Terms for any reason, your license to use the Application shall immediately and automatically terminate.',
  incorporationText:
    "These Terms are incorporated in any agreements for eScreen services. In the event any of the terms or provisions of these Terms shall be held tobe unenforceable, the remaining terms and provisions shall be unimpaired and the unenforceable term or provision shall be replaced by such enforceable term or provision as comes closest to the intentionunderlying the unenforceable term or provision. These Terms shall be subject to any other agreements you have entered into with eScreen. The user's access to and use of the Application, and the terms of this disclaimer are governed by the laws of the State of Kansas unless expressly stated otherwise in any other agreements you have entered into with eScreen. You agree that any legal action or proceeding between you and this Application will be brought exclusively in a federal or state court of competent jurisdiction sitting in the State of Kansas unless expressly stated otherwise in any other agreements you have entered into with eScreen.",
  changesServiceTermsText:
    'eScreen is not responsible for any technical inaccuracies or typographical errors which may be contained in these Terms. eScreen reserves the right to change these Terms in its sole discretion at any time upon posting such changes to this Application. It is your responsibility to review these Terms to determine if any changes have been made, as eScreen is not obligated to provide any other means of notice. Any such changes to the Terms will become effective immediately upon being posted on the Application. The Application and any services may be modified, temporarily or permanently, by eScreen at any time without notice to you. You agree that eScreen shall not be liable to you or to any third party for any modification of the Application or the services.',
  contactUsInstructions:
    'If you have questions or suggestions, please contact us at: 800-881-0722 option 5.',
  useAcceptance:
    'Use of this Application constitutes acceptance of the Terms of Use and Privacy Policy.',
};
