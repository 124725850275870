import { useContext } from 'react';
import { Context } from '../../../context/AppContextBuilder';
import { Box, Typography, LinearProgress, SxProps } from '@mui/material';

interface ProgressBarProps {
  activeQuestion: number;
  totalQuestions: number;
  barProgress: number;
}

export const ProgressBarSx: SxProps = {
  maxWidth: '500px',
  margin: '2rem auto',
  '& .MuiLinearProgress-root': {
    backgroundColor: 'secondary.main',
    borderRadius: 25,
    height: 4,
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: 'abbott.primaryBlue',
      borderRadius: 25,
      height: 4,
    },
  },
};

export const ProgressBar = ({
  activeQuestion,
  totalQuestions,
  barProgress,
}: ProgressBarProps) => {
  const { questionCounter } = useContext(Context);

  return (
    <Box sx={ProgressBarSx}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '0.75rem',
        }}
      >
        <Typography variant="h5" component="p">
          {questionCounter(activeQuestion, totalQuestions)}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={barProgress} />
    </Box>
  );
};
