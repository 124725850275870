import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { RatingChooser } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';

export interface ThankYouOverturnedProps {
  name: string;
  updateRating: (e: number | null) => void;
}

export const ThankYouTemplate = ({
  name,
  updateRating,
}: ThankYouOverturnedProps) => {
  const { nameTemplate, thankYouText } = useContext(Context);

  return (
    <Grid container spacing={3} justifyContent={'center'} zIndex={4}>
      <Grid item md={8} sm={7}>
        <Typography variant="h1" align="center">
          {nameTemplate(name)}
        </Typography>
      </Grid>

      <Grid item md={8} sm={6}>
        <Typography
          align="center"
          variant="h5"
          component="h2"
          fontWeight="normal"
          textTransform="none"
        >
          {thankYouText}
        </Typography>
      </Grid>
      <Grid item sm={12} />
      <Grid item>
        <RatingChooser
          highestRankMessage="highest"
          lowestRankMessage="lowest"
          title="Please rate your experience"
          onChange={(e: number | null) => updateRating(e)}
        />
      </Grid>
    </Grid>
  );
};
