import { useContext } from 'react';
import { Context } from '../../context/AppContextBuilder';
import { InterviewContext } from '../../context/InterviewContext';
import { InputButton, useResponsive } from '@abbott-components/core';
import { ValidateCodeStatus } from '../../utilities/verificationUtilities';
import {
  CollectSpecimen,
  LabAnalysis,
  MedicalReview,
  TestComplete,
} from '@abbott-components/icons';
import {
  Grid,
  Typography,
  Box,
  BoxProps,
  SvgIcon,
  SvgIconProps,
  SxProps,
  CircularProgress,
} from '@mui/material';

const primaryColor = '#008ECA';
const secondaryColor = '#BEBEBE';

const CheckMarkWrapper = () => (
  <CheckCircle
    sx={{
      color: primaryColor,
      top: '0.5rem',
      right: '-1rem',
      position: 'absolute',
    }}
  />
);

const CheckCircle = ({ ...props }: SvgIconProps) => (
  <SvgIcon data-testid="CheckCircleIcon" {...props}>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.57031 0.652344C9.88402 0.652344 12.5703 3.33864 12.5703 6.65234C12.5703 9.96605 9.88402 12.6523 6.57031 12.6523C3.2566 12.6523 0.570312 9.96605 0.570312 6.65234C0.570312 3.33864 3.2566 0.652344 6.57031 0.652344Z"
        fill={primaryColor}
      />
      <path
        d="M5.65705 7.22214L4.06266 5.62778L2.92383 6.76662L5.65705 9.49979L6.79588 8.36097L10.2124 4.94448L9.07352 3.80566L5.65705 7.22214Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

const DashLine = (props: BoxProps) => {
  return (
    <Box
      sx={{
        borderTop: `2px solid ${primaryColor}`,
        width: '20%',
        position: 'absolute',
        top: '3rem',
        left: '-0.9rem',
        ...props.sx,
      }}
    />
  );
};

const cardStyles: SxProps = {
  float: 'left',
  position: 'relative',
  borderRadius: '1rem',
  inlineSize: '20%',
  height: '8rem',
  margin: '0.4rem',
  textAlign: 'center',
  boxSizing: 'border-box',
  maxWidth: '5rem',
};

const iconStyles: SxProps = {
  color: primaryColor,
  marginTop: '1rem',
  padding: '0.8rem',
  border: `1.4px solid ${primaryColor}`,
  borderRadius: '0.4rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.121569)',
};

export type HomeTemplateProps = {
  clientName: string;
  isNextStepDisabled?: boolean;
  // Legal wants to remove the T&C checkbox but may change their mind (see PBI 173352).
  // termsAcknowledged?: boolean;
  // setTermsAcknowledged: (e: boolean) => void;
  // termsLink: string;
  /** Called when clicking "Let's get started" */
  nextStep: () => void;
};

export default function HomeTemplate({
  nextStep,
  clientName,
  isNextStepDisabled,
}: HomeTemplateProps) {
  const { md } = useResponsive();
  const {
    greeting,
    labAnalysis,
    testComplete,
    medicalReview,
    gettingStarted,
    reviewComplete,
    collectSpecimen,
    drugTestgreeting,
    interviewExplanation,
    mroTeamPendingReview,
    pendingMROReviewGreeting,
    completeInterviewGreeting,
  } = useContext(Context);
  const { interviewStatus } = useContext(InterviewContext);
  if (clientName.length === 0) {
    return (
      <Box textAlign="center">
        <CircularProgress data-testid="validatingCode" />
      </Box>
    );
  }
  var homeGreeting = greeting;
  var homeExplanation = interviewExplanation;
  if (interviewStatus === ValidateCodeStatus.PENDING_REVIEW) {
    homeGreeting = pendingMROReviewGreeting;
    homeExplanation = mroTeamPendingReview;
  } else if (interviewStatus === ValidateCodeStatus.COMPLETE) {
    homeGreeting = completeInterviewGreeting;
    homeExplanation = reviewComplete;
  }
  return (
    <Grid container justifyContent="center" display="flex">
      <Grid item md={10}>
        <Typography variant="h2" component="h1" align="center">
          {homeGreeting}
        </Typography>
        {interviewStatus === ValidateCodeStatus.PENDING_REVIEW ||
        interviewStatus === ValidateCodeStatus.COMPLETE ? null : (
          <Typography align="center" p={1}>
            {`${drugTestgreeting} `}
            <Typography component="span" fontWeight="bold" display="inline">
              {clientName}
            </Typography>
            .
          </Typography>
        )}
        <Typography align="center">{homeExplanation}</Typography>
      </Grid>

      <Grid item md={12} sm={12}>
        <Box
          width="100%"
          sx={{
            position: 'relative',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 0,
            marginBottom: '2rem',
          }}
        >
          {' '}
          <Box sx={cardStyles}>
            <CollectSpecimen
              fontSize={md ? 'large' : 'medium'}
              sx={iconStyles}
            />
            <CheckMarkWrapper />
            <Typography color="primary" fontSize="1rem" padding="0.5rem">
              {collectSpecimen}
            </Typography>
          </Box>
          <Box sx={cardStyles}>
            <DashLine />
            <LabAnalysis fontSize={md ? 'large' : 'medium'} sx={iconStyles} />
            <CheckMarkWrapper />
            <Typography color="primary" fontSize="1rem" padding="0.5rem">
              {labAnalysis}
            </Typography>
          </Box>
          <Box sx={cardStyles}>
            <DashLine />
            <MedicalReview
              fontSize={md ? 'large' : 'medium'}
              sx={
                interviewStatus === ValidateCodeStatus.COMPLETE
                  ? iconStyles
                  : {
                      ...iconStyles,
                      backgroundColor: primaryColor,
                      color: 'primary.contrastText',
                      boxShadow: 'none',
                    }
              }
            />
            {interviewStatus === ValidateCodeStatus.COMPLETE ? (
              <CheckMarkWrapper />
            ) : null}
            <Typography color="primary" fontSize="1rem" padding="0.5rem">
              {medicalReview}
            </Typography>
          </Box>
          <Box sx={cardStyles}>
            <DashLine sx={{ borderTopColor: secondaryColor }} />
            <TestComplete
              fontSize={md ? 'large' : 'medium'}
              sx={
                interviewStatus === ValidateCodeStatus.COMPLETE
                  ? {
                      ...iconStyles,
                      backgroundColor: primaryColor,
                      color: 'primary.contrastText',
                      boxShadow: 'none',
                    }
                  : {
                      ...iconStyles,
                      color: secondaryColor,
                      borderColor: secondaryColor,
                      boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.160784)',
                    }
              }
            />
            <Typography color="secondary.dark" fontSize="1rem" padding="0.5rem">
              {testComplete}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item md={12} sm={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="2rem"
        >
          {interviewStatus === ValidateCodeStatus.PENDING_REVIEW ? null : (
            <InputButton
              // disabled={!termsAcknowledged}
              disabled={isNextStepDisabled}
              name="GetStarted"
              onClick={nextStep}
            >
              {gettingStarted}
            </InputButton>
          )}
        </Box>
      </Grid>
      <Grid item md={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          // onClick={() => setTermsAcknowledged(!termsAcknowledged)}
        >
          {/* Removing the checkbox */}
          {/* <FormControlLabel
            control={
              <Checkbox
                sx={{ '&.Mui-checked': { color: primaryColor } }}
                data-testid="terms-acknowledged-checkbox"
                checked={termsAcknowledged}
              />
            }
            label={
              <Typography fontSize={'12px'}>
                I acknowledge I have read and understand the{' '}
                <Link href="https://www.abbott.com/privacy-policy.html">
                  Privacy Policy
                </Link>{' '}
                and agree to the <Link href={termsLink}>Terms & Conditions</Link>
              </Typography>
            }
          /> */}
        </Box>
      </Grid>
    </Grid>
  );
}
