import { InferType } from 'prop-types';
import { PublicPaths } from '../../routing/FionaRouteProvider';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import {
  pathBuilder,
  canceledLockedOutRedirect,
} from '../../utilities/commonUtilities';
import {
  validateCode,
  LockedOut,
  Canceled,
  CodeValidationResponseSchema,
  ValidateCodeStatus,
} from '../../utilities/verificationUtilities';

export async function verificationLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;
  if (!interviewCode) {
    return redirect(pathBuilder({ path: PublicPaths.NO_CODE_CANCELED }));
  }

  try {
    const interviewInfo = await validateCode(interviewCode);
    const errorPath = canceledLockedOutRedirect(
      interviewInfo as LockedOut | Canceled,
      interviewCode,
    );

    if (errorPath.length > 0) {
      return redirect(errorPath);
    } else {
      sessionStorage.setItem('lockedOut', 'false');
    }

    if (
      (interviewInfo as InferType<typeof CodeValidationResponseSchema>)
        .interviewStatus === ValidateCodeStatus.PENDING_REVIEW
    ) {
      return redirect(
        pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
      );
    }
  } catch {
    return redirect(
      pathBuilder({ path: PublicPaths.CANCELED, code: interviewCode }),
    );
  }
  return null;
}
