import { InterviewProvider } from './context/InterviewContext';
import { FionaRouteProvider } from './routing/FionaRouteProvider';

const App = () => {
  return (
    <InterviewProvider>
      <FionaRouteProvider />
    </InterviewProvider>
  );
};

export default App;
