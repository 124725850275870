import { useResponsive } from '@abbott-components/core';
import { CustomRadio } from '../../shared/custom-radio/CustomRadio';
import {
  applyActiveValue,
  parseQuestionOptionsTexts,
} from '../../../utilities/interviewUtilities';
import { Box, Grid, Typography } from '@mui/material';
import { DropDown } from '../dropdown/DropDown';

export interface IQuestion {
  analyteName: any;
  questionId: number;
  questionText: string;
  questionOrder: number;
  questionResponseOption: number;
  questionResponseText: any;
  questionType: string;
  questionOptions: QuestionOption[];
  maxResponse: number;
  isRequired: boolean;
  isActive: boolean;
  specialInstructions: any;
  childQuestionId?: number;
  childQuestion?: IQuestion;
  questionResponseId?: number;
}

export interface QuestionOption {
  text: string;
  isActive: boolean;
  noticeText?: string;
  questionOptionId: number;
  isRXUploadOption: boolean;
  isSelectedResponse: boolean;
  rxUploadDescription: string;
  isEndInterviewOption: boolean;
  isChildQuestionOption: boolean;
}

export type AnswerEntry = {
  questionId: string | number;
  value?: QuestionOption | string;
};

export interface QuestionProps {
  question: IQuestion;
  answers: AnswerEntry[];
  handleMultipleChoiceInput?: (
    value: string,
    isChildQuestion?: boolean,
  ) => void;
  isChildQuestion?: boolean;
}

export const Question = ({
  question,
  handleMultipleChoiceInput,
  answers,
  isChildQuestion = false,
}: QuestionProps) => {
  const { md } = useResponsive();
  const questionChoicesAmount = question?.questionOptions.length;
  const QuestionOptions = parseQuestionOptionsTexts(question);
  return (
    <>
      <Grid item xs={12} mb={4}>
        <Box display="flex" justifyContent={'center'}>
          <Typography variant={md ? 'h1' : 'h3'} fontWeight="bold">
            {question?.questionText}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            maxWidth: 500,
            margin: '0 auto',
            '& .MuiInputBase-root': {
              height: '3.1rem',
            },
          }}
        >
          {questionChoicesAmount < 5 && questionChoicesAmount !== 0 ? (
            <CustomRadio
              name={`${question?.questionId}-radio-field`}
              onChange={(value) => {
                if (handleMultipleChoiceInput) {
                  handleMultipleChoiceInput(value, isChildQuestion);
                }
              }}
              radioOptions={QuestionOptions}
              value={applyActiveValue(answers, question)}
            />
          ) : (
            <DropDown
              question={question}
              QuestionOptions={QuestionOptions}
              answers={answers}
              handleInput={(value) => {
                if (handleMultipleChoiceInput) {
                  handleMultipleChoiceInput(value, isChildQuestion);
                }
              }}
            />
          )}
        </Box>
      </Grid>
    </>
  );
};
