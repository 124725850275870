import { useContext } from 'react';
import { SxProps } from '@mui/material/styles';
import { Close } from '@abbott-components/icons';
import { Context } from '../../../context/AppContextBuilder';
import { pathBuilder } from '../../../utilities/commonUtilities';
import { PublicPaths } from '../../../routing/FionaRouteProvider';
import { logout } from '../../../utilities/verificationUtilities';
import { InterviewContext } from '../../../context/InterviewContext';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  List,
  Drawer,
  ListItem,
  IconButton,
  ListItemText,
  ListItemButton,
} from '@mui/material';

interface MenuProps {
  isOpen: boolean;
  closeMenu(): void;
}

const ListItemSx: SxProps = { marginBottom: '2rem' };
const ListItemTextSx: SxProps = {
  '& .MuiTypography-root': { color: 'white', fontSize: '1.375rem' },
};

const NavMenu = ({ isOpen, closeMenu }: MenuProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { faq, logoutText } = useContext(Context);
  const { interviewCode } = useContext(InterviewContext);
  const matches = useMatch(
    pathBuilder({ path: PublicPaths.FAQ, code: interviewCode }),
  );

  const goTo = (url: string) => {
    navigate(url, {
      replace:
        pathname ===
          pathBuilder({ path: PublicPaths.FAQ, code: interviewCode }) ||
        pathname === pathBuilder({ path: PublicPaths.NO_CODE_FAQ }),
    });
    closeMenu();
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paperAnchorRight': {
          backgroundColor: 'rgba(0,0,0,0)',
          boxShadow: '-12px 0px 15px #00000039',
        },
      }}
      open={isOpen}
      anchor="right"
      onClose={closeMenu}
    >
      <List
        sx={{
          zIndex: 3,
          width: '80vw',
          height: '100vh',
          backgroundColor: '#008ECA',
          backgroundImage: 'url("images/menu-active.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <IconButton
          id="closeMenu"
          onClick={closeMenu}
          sx={{ padding: '1rem 1.25rem', marginBottom: '2rem' }}
        >
          <Close sx={{ color: 'primary.contrastText', width: '1.5rem' }} />
        </IconButton>
        <ListItem sx={ListItemSx}>
          <ListItemButton
            component="a"
            onClick={() =>
              goTo(
                matches
                  ? pathBuilder({
                      code: interviewCode,
                      path: PublicPaths.FAQ,
                    })
                  : pathBuilder({ path: PublicPaths.NO_CODE_FAQ }),
              )
            }
          >
            <ListItemText sx={ListItemTextSx}>{faq}</ListItemText>
          </ListItemButton>
        </ListItem>
        {!!sessionStorage?.jwt && (
          <ListItem sx={ListItemSx}>
            <ListItemButton
              component="a"
              onClick={() => {
                closeMenu();
                logout();
                navigate(
                  pathBuilder({
                    code: interviewCode,
                    path: PublicPaths.HOME,
                  }),
                );
              }}
            >
              <ListItemText sx={ListItemTextSx}>{logoutText}</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default NavMenu;
