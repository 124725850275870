import { InputButton } from '@abbott-components/core';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../../../context/AppContextBuilder';

interface NavButtonProps {
  handleBackClick: () => void;
  handleNextClick: () => void;
  activeQuestion?: number;
  allowNext: boolean;
  loading: boolean;
}

export const NavButtons = ({
  handleBackClick,
  handleNextClick,
  activeQuestion,
  allowNext,
  loading = false,
}: NavButtonProps) => {
  const { nextButton, backButton } = useContext(Context);

  return (
    <>
      {activeQuestion !== 1 && (
        <Grid item xs={6} sm={4} md={3} justifyContent="center">
          <InputButton name="back" color="secondary" onClick={handleBackClick}>
            {backButton}
          </InputButton>
        </Grid>
      )}
      <Grid
        item
        xs={6}
        sm={4}
        md={3}
        sx={{
          display: 'flex',
        }}
        justifyContent="center"
      >
        <InputButton
          name="next"
          loading={loading}
          disabled={allowNext}
          onClick={handleNextClick}
        >
          {nextButton}
        </InputButton>
      </Grid>
    </>
  );
};
