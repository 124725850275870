import { pathBuilder } from '../../utilities/commonUtilities';
import { InterviewStatus } from '../../context/InterviewContext';
import { isTokenValid } from '../../utilities/verificationUtilities';
import { LoaderFunctionArgs, json, redirect } from 'react-router-dom';
import { IQuestion } from '../../components/interview/question/Question';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import {
  getCurrentQuestionOrderId,
  getInterviewData,
} from '../../utilities/interviewUtilities';

export async function thankyouLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;
  if (!interviewCode) {
    return redirect(pathBuilder({ path: PublicPaths.NO_CODE_CANCELED }));
  }

  const lockedOut = sessionStorage.getItem('lockedOut') === 'true';
  if (!isTokenValid(lockedOut)) {
    return redirect(
      pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
    );
  }

  let interviewData;
  try {
    interviewData = await getInterviewData(interviewCode);

    // Status based routing
    if (interviewData.interviewStatus === InterviewStatus.New) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.WELCOME,
        }),
      );
    }

    if (interviewData.interviewStatus === InterviewStatus.Partial) {
      const questionOrderToRedirectTo = getCurrentQuestionOrderId(
        interviewData.interviewQuestions as IQuestion[],
      );
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.INTERVIEW,
          question: questionOrderToRedirectTo ?? 1,
        }),
      );
    }
  } catch (e) {
    const error = e as Error;
    if (error.message === 'Error: Session timed out.') {
      return json({ errorMessage: error.message, timedout: true });
    }
    return json({ errorMessage: error.message });
  }

  return json(interviewData);
}
