import { ChangeEvent, useContext } from 'react';
import { date, object, string } from 'yup';
import { Box, Typography } from '@mui/material';
import {
  Show,
  Form,
  InputButton,
  useResponsive,
  InputTextField,
  DatePickerTextField,
} from '@abbott-components/core';
import { CustomRadio } from '../../components/shared/custom-radio/CustomRadio';
import type { IRadioOption } from '../../components/shared/custom-radio/CustomRadio';
import { Context } from '../../context/AppContextBuilder';

export interface VerificationTemplateProps {
  currentStep: 1 | 2;
  handleNameInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDateInput: (e: string | Date | null) => void;
  clinics: IRadioOption[];
  selectedClinic: string;
  handleClinicSelection: (site: string) => void;
  handleNextClick: () => void;
  buttonDisabled: boolean;
  loading: boolean;
}

export const VerificationTemplate = ({
  clinics,
  loading,
  currentStep,
  selectedClinic,
  buttonDisabled,
  handleNameInput,
  handleDateInput,
  handleNextClick,
  handleClinicSelection,
}: VerificationTemplateProps) => {
  const { md } = useResponsive();
  const {
    nextButton,
    confirmText,
    verifyLastName,
    dobPickerLabel,
    whereDidTakeTest,
    makeSureYouHeader,
    step1VerificationSubheader,
    step2VerificationSubheader,
  } = useContext(Context);

  const validForm = object({
    lastName: string(),
    dateOfBirth: date(),
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          margin: '0 1rem 1rem',
          flexDirection: 'column',
        }}
      >
        <Typography component="h1" variant={md ? 'h2' : 'h4'} mb="1rem">
          {makeSureYouHeader}
        </Typography>
        <Typography
          component="h2"
          variant={md ? 'h2' : 'h4'}
          marginBottom={'1rem'}
        >
          {currentStep === 1
            ? step1VerificationSubheader
            : step2VerificationSubheader}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          marginBottom: '1rem',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Show
          when={currentStep === 1}
          fallback={
            <>
              <CustomRadio
                name="clinic-location"
                radioOptions={clinics}
                value={selectedClinic}
                label={whereDidTakeTest}
                onChange={handleClinicSelection}
              />
              <Box sx={{ height: '1rem' }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <InputButton
                  name="submit"
                  type="submit"
                  loading={loading}
                  onClick={handleNextClick}
                  disabled={buttonDisabled}
                >
                  {confirmText}
                </InputButton>
              </Box>
            </>
          }
        >
          <Form
            validationSchema={validForm}
            onFormSubmit={handleNextClick}
            formOptions={{
              defaultValues: {
                lastName: '',
                dateOfBirth: null,
              },
            }}
          >
            <Box sx={{ maxWidth: 500, marginBottom: '1.5rem' }}>
              <InputTextField
                name="lastName"
                margin="normal"
                label={verifyLastName}
                onChange={handleNameInput}
              />
              <DatePickerTextField
                disableFuture
                openTo="year"
                name="dateOfBirth"
                label={dobPickerLabel}
                onChange={handleDateInput}
                referenceDate={new Date('01/01/2000')}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <InputButton
                name="submit"
                type="submit"
                loading={loading}
                disabled={buttonDisabled}
              >
                {nextButton}
              </InputButton>
            </Box>
          </Form>
        </Show>
      </Box>
    </>
  );
};
