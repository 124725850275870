import { InputButton } from '@abbott-components/core';
import { Grid, Typography } from '@mui/material';
import { provideUploadTimeRemaining } from '../../utilities/thankYouUtilities';
import { Context } from '../../context/AppContextBuilder';
import { useContext } from 'react';

export interface ThankYouMissingUploadProps {
  /**upload date in UTC time */
  uploadByDate: Date | undefined;
  triggerUploadNow: () => void;
  handleMissedDeadline: () => void;
}

export const ThankYouMissingUpload = ({
  uploadByDate,
  triggerUploadNow,
  handleMissedDeadline,
}: ThankYouMissingUploadProps) => {
  const { uploadNow, photoReminder, timeRemainingText } = useContext(Context);

  const timeRemaining = provideUploadTimeRemaining(
    handleMissedDeadline,
    uploadByDate,
  );
  return (
    <Grid container spacing={3} justifyContent={'center'} zIndex={4}>
      <Grid item md={8} sm={7}>
        <Typography variant="h1" align="center">
          {photoReminder}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography
          align="center"
          variant="h5"
          fontWeight="normal"
          textTransform="none"
          component="h2"
        >
          {timeRemainingText} <b>{timeRemaining}</b>
        </Typography>
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={12}>
        <InputButton name={'UploadNow'} onClick={triggerUploadNow}>
          {uploadNow}
        </InputButton>
      </Grid>
    </Grid>
  );
};
