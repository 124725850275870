import { Terms } from '../pages/Terms';
import { faqLoader, FAQ } from '../pages/FAQ';
import { FAQError } from '../pages/FAQ/FAQError';
import { Home, homeLoader } from '../pages/Home';
import { uploadLoader, RxUpload } from '../pages/Upload';
import { welcomeLoader, Welcome } from '../pages/Welcome';
import { pathBuilder } from '../utilities/commonUtilities';
import { Layout } from '../components/shared/layout/Layout';
import { thankyouLoader, ThankYou } from '../pages/ThankYou';
import { CanceledInterview } from '../pages/CanceledInterview';
import { interviewLoader, Interview } from '../pages/Interview';
import { reanalysisLoader, Reanalysis } from '../pages/Reanalysis';
import { Verification, verificationLoader } from '../pages/Verification';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

export enum PrivatePaths {
  UPLOAD = '/:interviewCode/authorized/upload',
  WELCOME = '/:interviewCode/authorized/welcome',
  THANK_YOU = '/:interviewCode/authorized/thank-you',
  REANALYSIS = '/:interviewCode/authorized/reanalysis',
  INTERVIEW = '/:interviewCode/authorized/interview/:question',
}

export enum PublicPaths {
  NO_CODE_FAQ = '/FAQ',
  NO_CODE_TERMS = '/terms',
  HOME = '/:interviewCode',
  FAQ = '/:interviewCode/FAQ',
  NO_CODE_CANCELED = '/canceled',
  TERMS = '/:interviewCode/terms',
  NO_CODE_FAQ_ERROR = '/faq-error',
  CANCELED = '/:interviewCode/canceled',
  FAQ_ERROR = '/:interviewCode/faq-error',
  VERIFICATION = '/:interviewCode/verification',
  UNAUTHORIZED = '/:interviewCode/unauthorized',
}

export type AppPaths = PrivatePaths | PublicPaths | '*';

export const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        element: <Home />,
        loader: homeLoader,
        path: PublicPaths.HOME,
        errorElement: <CanceledInterview />,
      },
      {
        element: <Verification />,
        loader: verificationLoader,
        path: PublicPaths.VERIFICATION,
      },
      {
        path: PublicPaths.UNAUTHORIZED,
        element: <CanceledInterview />,
      },
      {
        element: <FAQError />,
        path: PublicPaths.FAQ_ERROR,
      },
      {
        element: <FAQError />,
        path: PublicPaths.NO_CODE_FAQ_ERROR,
      },
      {
        element: <CanceledInterview />,
        path: PublicPaths.NO_CODE_CANCELED,
      },
      {
        element: <Welcome />,
        loader: welcomeLoader,
        path: PrivatePaths.WELCOME,
        errorElement: (
          <Navigate to={pathBuilder({ path: PublicPaths.NO_CODE_CANCELED })} />
        ),
      },
      {
        element: <Interview />,
        loader: interviewLoader,
        path: PrivatePaths.INTERVIEW,
      },
      {
        loader: uploadLoader,
        element: <RxUpload />,
        path: PrivatePaths.UPLOAD,
      },
      {
        element: <ThankYou />,
        loader: thankyouLoader,
        path: PrivatePaths.THANK_YOU,
      },
      {
        element: <Reanalysis />,
        loader: reanalysisLoader,
        path: PrivatePaths.REANALYSIS,
      },
      {
        element: <Terms />,
        path: PublicPaths.NO_CODE_TERMS,
      },
      {
        element: <FAQ />,
        loader: faqLoader,
        path: PublicPaths.FAQ,
      },
      {
        element: <FAQ />,
        loader: faqLoader,
        path: PublicPaths.NO_CODE_FAQ,
      },
      {
        element: <Terms />,
        path: PublicPaths.TERMS,
      },
      {
        path: PublicPaths.CANCELED,
        element: <CanceledInterview />,
      },
      {
        path: '*',
        element: <CanceledInterview />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export const FionaRouteProvider = () => <RouterProvider router={router} />;
