import React from 'react';
import Warning from '@mui/icons-material/Warning';
import { InputRadioGroup, useResponsive } from '@abbott-components/core';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  Typography,
} from '@mui/material';

export interface IRadioOption {
  text: string;
  noticeText?: string;
}

interface CustomRadioProps {
  name: string;
  label?: string;
  value?: string;
  radioOptions?: IRadioOption[];
  onChange?: (selection: string) => void;
}

const provideRadioListSx = (md: boolean) =>
  ({
    width: '100%',
    margin: '0 auto',
    '& .MuiList-root': { width: '100%', padding: 0 },
    '& .MuiFormLabel-root': {
      fontWeight: 700,
      fontSize: '1rem',
      color: 'abbott.charcoal',
      textAlign: 'center',
    },
    '& .MuiTypography-root': {
      margin: '0 0 0 0.25rem',
      fontWeight: 500,
      fontSize: '1rem',
      color: 'secondary.contrastText',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      minWidth: '100%',
      minHeight: '100%',
    },
    '& .MuiButtonBase-root': {
      '&.MuiListItemButton-root': {
        width: '100%',
        maxWidth: 500,
        ':hover': {
          boxShadow: `0 1px 4px 3px #0000000F`,
        },
      },
      '& .MuiRadio-root': {
        padding: '4px',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: md ? '1.75rem' : '1.5rem',
    },
  } as SxProps);

const SelectedListButtonSx: SxProps = {
  '&.MuiButtonBase-root': {
    '&.MuiListItemButton-root': {
      height: '3em',
      padding: 0,
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: 'primary.main',
      borderWidth: '2px',
      boxShadow: `0 1px 4px 3px #0000000F`,
    },
  },
};

const UnselectedListButtonSx: SxProps = {
  '&.MuiButtonBase-root': {
    '&.MuiListItemButton-root': {
      height: '3em',
      padding: 0,
      borderRadius: '8px',
      borderStyle: 'solid',
      borderColor: 'secondary.main',
      borderWidth: '1px',
    },
  },
};

export const CustomRadio = ({
  name,
  label,
  value,
  onChange,
  radioOptions,
}: CustomRadioProps) => {
  const { md } = useResponsive();

  return (
    <Box
      sx={{
        ...provideRadioListSx(md),
        '& .MuiFormGroup-root': { marginTop: label ? '1rem' : '' },
      }}
    >
      <InputRadioGroup
        selectedValue={value || ' '}
        name={name}
        label={label}
        onSelectionChange={onChange}
        isRow={true}
      >
        <List>
          {radioOptions?.map((option, index) => {
            const doesHaveNoticeText = !!option.noticeText;
            const lastRadio = index === radioOptions.length - 1;
            const currentRadioIsSelected = value === option.text;
            const ListButtonSx =
              value === option.text
                ? SelectedListButtonSx
                : UnselectedListButtonSx;

            let bottomRadioMargin = '';
            if (doesHaveNoticeText && currentRadioIsSelected) {
              bottomRadioMargin = '0 auto 8px';
            } else if (lastRadio) {
              bottomRadioMargin = '0 auto';
            } else {
              bottomRadioMargin = '0 auto 1.5rem';
            }

            return (
              <React.Fragment key={option.text}>
                <ListItemButton
                  tabIndex={-1}
                  sx={{ ...ListButtonSx, margin: bottomRadioMargin }}
                >
                  <InputRadioGroup.Radio value={option.text}>
                    {option.text}
                  </InputRadioGroup.Radio>
                </ListItemButton>
                {doesHaveNoticeText && currentRadioIsSelected && (
                  <ListItemText
                    sx={{
                      maxWidth: '500px',
                      alignItems: 'center',
                      margin: '0 auto 1.5rem',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Warning
                        sx={{
                          width: '1.2rem',
                          height: '1.1rem',
                          color: 'abbott.gold',
                        }}
                      />
                      <Typography fontStyle="italic" textAlign="left">
                        {option.noticeText}
                      </Typography>
                    </Box>
                  </ListItemText>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </InputRadioGroup>
    </Box>
  );
};
