import App from './App';
import { EMITheme } from './Theme';
import TagManager from 'react-gtm-module';
import { createTheme } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { AppProvider } from './context/AppContextBuilder';

import './custom.css';

const env = process.env.NODE_ENV;

const tagManagerArgs = {
  gtmId: 'GTM-K84J7S2',
  auth:
    env === 'development' ? 'dJs-1ag4WzK_9Tvwtr-Z4A' : 'ui5Eg-uBN5EP8pTgn26e8g',
  preview: env === 'development' ? 'env-9' : 'env-1',
  dataLayer: {
    userId: 'TestUser001',
  },
};
TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

/**Context provider wrapping here*/
root.render(
  <AppProvider theme={createTheme(EMITheme)}>
    <App />
  </AppProvider>,
);
