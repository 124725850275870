import { useContext } from 'react';
import { Context } from '../../context/AppContextBuilder';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import { InputButton, useResponsive } from '@abbott-components/core';
import { ArrowCircleRight, ThumbsUp, Warning } from '@abbott-components/icons';

export interface WelcomeTemplateProps {
  name: string;
  specimenTypes: string[];
  continueClick: () => void;
}
const primaryColor = '#008ECA';
const textPaddingBottom = '0.6rem';

export const WelcomeTemplate = ({
  name,
  specimenTypes,
  continueClick,
}: WelcomeTemplateProps) => {
  const { lg, md, sm } = useResponsive();
  const {
    labFound,
    nextSteps,
    nameTemplate,
    evaluatedText,
    specimenHeader,
    continueButton,
    medicalReviewRequired,
    interviewIntroduction,
  } = useContext(Context);

  const getMaxWidth = () => {
    if (!lg && (md || sm)) {
      return '75%';
    } else {
      return '100%';
    }
  };
  const boxStyling: SxProps = {
    m: '0.5rem',
    height: '100%',
    padding: '0.5rem',
    borderWidth: '1.9px',
    borderRadius: '1rem',
    border: `2px ${primaryColor} solid`,
  };

  const DashLine = () => {
    if (lg) {
      return (
        <div
          style={{
            top: '55%',
            float: 'right',
            width: '1.5rem',
            right: `-1.81rem`,
            position: 'relative',
            border: `1.5px solid ${primaryColor}`,
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="stretch">
      <Grid
        item
        sm={12}
        display="flex"
        justifyContent="center"
        mb={textPaddingBottom}
      >
        <Typography
          variant="h2"
          component="h1"
          align="center"
          fontWeight="bold"
          paddingTop="1rem"
        >
          {nameTemplate(name, false)}
        </Typography>
      </Grid>
      <Grid item lg={4} sm={12} xs={12}>
        <DashLine />
        <Box
          sx={lg ? boxStyling : null}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box textAlign="center">
            <Warning sx={{ color: 'abbott.primary' }} fontSize="large" />
          </Box>

          <Typography
            variant="h5"
            component="h2"
            align="center"
            paddingBottom={textPaddingBottom}
          >
            {specimenHeader}
          </Typography>
          <Typography align="center" maxWidth={getMaxWidth()}>
            {labFound} <br />
            {specimenTypes.map((specimenType) => (
              <span key={specimenType}>
                <b>{specimenType}</b>
                <br />
              </span>
            ))}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={4} sm={12}>
        <DashLine />
        <Box
          sx={lg ? boxStyling : null}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Box textAlign="center">
            <ThumbsUp sx={{ color: 'abbott.primary' }} fontSize="large" />
          </Box>
          <Typography
            variant="h5"
            component="h2"
            paddingBottom={textPaddingBottom}
          >
            {medicalReviewRequired}
          </Typography>
          <Typography
            align="center"
            maxWidth={getMaxWidth()}
            paddingBottom={textPaddingBottom}
          >
            {evaluatedText}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={4} sm={12}>
        <Box
          sx={lg ? boxStyling : null}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box textAlign="center">
            <ArrowCircleRight
              sx={{ color: 'abbott.primary' }}
              fontSize="large"
            />
          </Box>
          <Typography
            variant="h5"
            component="h2"
            align="center"
            paddingBottom={textPaddingBottom}
          >
            {nextSteps}
          </Typography>
          <Typography
            align="center"
            paddingBottom={textPaddingBottom}
            maxWidth={getMaxWidth()}
          >
            {interviewIntroduction}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={2} mt="2rem" display="flex" justifyContent="center">
        <InputButton name="continue" onClick={continueClick}>
          {continueButton}
        </InputButton>
      </Grid>
    </Grid>
  );
};
