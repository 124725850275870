import { useContext } from 'react';
import { Context } from '../context/AppContextBuilder';
import { InterviewContext } from '../context/InterviewContext';
import { CancelledInterviewTemplate } from '../templates/CanceledTemplate';

export const CanceledInterview = () => {
  const { emiPrefix, canceledInterview } = useContext(Context);
  document.title = `${emiPrefix} ${canceledInterview}`;
  const { mroPhoneNumber } = useContext(InterviewContext);
  return <CancelledInterviewTemplate phone={mroPhoneNumber} />;
};
