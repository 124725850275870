import { AbbottTheme, fontSizes } from '@abbott-components/core';
import { ThemeOptions } from '@mui/material';

export const EMITheme = {
  ...AbbottTheme,
  typography: {
    ...AbbottTheme.typography,
    h1: {
      ...(AbbottTheme.typography as any)['h1'],
      textTransform: 'none',
      fontWeight: 'bold',
      color: AbbottTheme.palette?.abbott.charcoal,
    },
    h2: {
      ...(AbbottTheme.typography as any)['h2'],
      color: AbbottTheme.palette?.abbott.charcoal,
      textTransform: 'none',
    },
    h3: {
      ...(AbbottTheme.typography as any)['h3'],
      color: AbbottTheme.palette?.abbott.charcoal,
      textTransform: 'none',
    },
    h4: {
      ...(AbbottTheme.typography as any)['h4'],
      color: AbbottTheme.palette?.abbott.darkGray,
      textTransform: 'none',
    },
    h5: {
      ...(AbbottTheme.typography as any)['h5'],
      color: AbbottTheme.palette?.abbott.charcoal,
      textTransform: 'none',
    },
    body1: {
      ...(AbbottTheme.typography as any)['body1'],
      fontSize: fontSizes.s,
    },
    body2: {
      ...(AbbottTheme.typography as any)['body2'],
      color: AbbottTheme.palette?.abbott.darkGray,
      fontWeight: 700,
      fontSize: '12px',
    },
  },
  palette: {
	...AbbottTheme.palette,
	secondary: {
		...AbbottTheme.palette?.secondary,
		dark: AbbottTheme.palette?.abbott.darkGray
	}
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '7.75rem',
          minHeight: '2.5rem',
        },
      },
    },
  },
} as ThemeOptions;
