import { InferType } from 'yup';
import type { Id } from 'react-toastify';
import { Box, Grid } from '@mui/material';
import { InterviewData } from '../../FionaTypes';
import { useInterview } from '../../hooks/useInterview';
import { useState, useEffect, useContext } from 'react';
import { useAbbottToast } from '@abbott-components/core';
import type { NavigateFunction } from 'react-router-dom';
import { Context } from '../../context/AppContextBuilder';
import type { DisplayAbbottToastProps } from '@abbott-components/core';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import { NavButtons } from '../../components/interview/nav-buttons/NavButtons';
import { ProgressBar } from '../../components/interview/progress-bar/ProgressBar';
import { InterviewTemplate } from '../../templates/InterviewTemplate/InterviewTemplate';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';
import {
  DispositionStatus,
  InterviewContext,
  InterviewStatus,
} from '../../context/InterviewContext';
import {
  InterviewDataValidationSchema,
  QuestionResponseInputData,
  createQuestionResponse,
  extractStoredAnswers,
  getCurrentQuestionOrderId,
  handleAnswerInput,
  markQuestionsComplete,
  updateQuestionResponse,
} from '../../utilities/interviewUtilities';
import {
  IQuestion,
  QuestionOption,
} from '../../components/interview/question/Question';

export type AnswerEntry = {
  questionId: string | number;
  value?: QuestionOption | string;
};

const handleErrorResponse = (
  error: Error,
  interviewCode: string,
  displayMessage: string,
  displayAbbottToast: ({
    type,
    message,
    position,
    onClose,
    ariaRole,
    autoHideDuration,
  }: DisplayAbbottToastProps) => Id,
  navigate: NavigateFunction,
) => {
  if (error.message === 'Error: Session timed out') {
    displayNetworkError(displayAbbottToast, displayMessage);
    navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
  } else {
    displayNetworkError(displayAbbottToast);
  }
};

export type ValidInterviewData = InferType<
  typeof InterviewDataValidationSchema
>;

export const Interview = () => {
  const {
    setSpecId,
    setStatus,
    questions,
    setLoading,
    setFirstName,
    setQuestions,
    interviewCode,
    setAnalyteNames,
    setRxExpiration,
    setDispositionId,
    setMroPhoneNumber,
    electronicInterviewID,
    setElectronicInterviewID,
  } = useContext(InterviewContext);

  const data = useLoaderData();
  const { question } = useParams();
  const navigate = useNavigate();
  const { displayAbbottToast } = useAbbottToast();
  const [isSaving, setIsSaving] = useState(false);
  const [answers, setAnswers] = useState<AnswerEntry[]>([]); // we'll probably use a utility function here to draw out our questions with answers from context
  const { sessionExpired, emiPrefix, genericLoadError, interviewQuestion } =
    useContext(Context);

  useEffect(() => {
    if ((data as { timedout: boolean }).timedout) {
      displayNetworkError(displayAbbottToast, sessionExpired);
      navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
    } else if ((data as { errorMessage: string }).errorMessage) {
      displayNetworkError(displayAbbottToast, genericLoadError);
      navigate(
        pathBuilder({ path: PublicPaths.UNAUTHORIZED, code: interviewCode }),
      );
    }
  }, [
    data,
    navigate,
    interviewCode,
    sessionExpired,
    genericLoadError,
    displayAbbottToast,
  ]);

  useEffect(() => {
    const {
      specId,
      mroInfo,
      donorInfo,
      analyteNames,
      rxExpiration,
      dispositionId,
      interviewStatus,
      interviewQuestions,
      electronicInterviewId,
    } = data as ValidInterviewData;

    if (!(data as { errorMessage: string }).errorMessage) {
      setSpecId(specId);
      if (donorInfo?.firstName) {
        setFirstName(donorInfo.firstName);
      }
      setAnalyteNames(analyteNames);
      setRxExpiration(new Date(rxExpiration || ''));
      setStatus(interviewStatus as InterviewStatus);
      if (questions) {
        setQuestions(interviewQuestions as IQuestion[]);
      }
      setElectronicInterviewID(electronicInterviewId);
      setDispositionId(dispositionId as DispositionStatus);
      setLoading(false);
      if (mroInfo?.mroPhone) {
        setMroPhoneNumber(
          mroInfo.mroPhone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
        );
      }
    }

    // Disabling the linter here because these are all set state functions which are identities and thus
    // do not need to be listed here as per the react docs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [activeQuestionOrderId, setActiveQuestionOrderId] = useState<number>(
    Number(question) || 1,
  );

  const { showChild, allowNext } = useInterview(
    answers,
    activeQuestionOrderId - 1,
    questions,
  );

  const [isLocalUpdated, setIsLocalUpdated] = useState(false);
  document.title = `${emiPrefix} ${interviewQuestion} ${question}`;

  useEffect(() => {
    let questionToNavigateTo = activeQuestionOrderId;
    if (
      questionToNavigateTo > questions.length &&
      !(data as { errorMessage: string }).errorMessage
    ) {
      const currentQuestion = getCurrentQuestionOrderId(questions);
      if (currentQuestion) {
        questionToNavigateTo = currentQuestion;
        if (questions[questionToNavigateTo - 1]?.questionOrder) {
          navigate(
            pathBuilder({
              code: interviewCode,
              path: PrivatePaths.INTERVIEW,
              question: questions[questionToNavigateTo - 1].questionOrder,
            }),
          );
        }
      }
    }
  }, [data, questions, interviewCode, navigate, activeQuestionOrderId]);

  useEffect(() => {
    if (!isLocalUpdated && !(data as { errorMessage: string }).errorMessage) {
      setAnswers(extractStoredAnswers(questions));
    }
  }, [data, isLocalUpdated, setAnswers, questions]);

  const handleBackClick = () => {
    let pathToNavigatTo = '';
    let questionToNavigateTo = activeQuestionOrderId - 1;
    if (questionToNavigateTo > 0) {
      setActiveQuestionOrderId(questionToNavigateTo);
      pathToNavigatTo = pathBuilder({
        code: interviewCode,
        path: PrivatePaths.INTERVIEW,
        question: questionToNavigateTo,
      });
    } else {
      setActiveQuestionOrderId(1);
      pathToNavigatTo = pathBuilder({
        question: 1,
        code: interviewCode,
        path: PrivatePaths.INTERVIEW,
      });
    }
    navigate(pathToNavigatTo);
  };

  const moveToNextStep = () => {
    if (activeQuestionOrderId === questions.length) {
      markQuestionsComplete(electronicInterviewID)
        .then((data: InterviewData) => {
          setStatus(data.interviewStatus as InterviewStatus);
          setRxExpiration(new Date(data.rxExpiration));
          switch (data.interviewStatus) {
            case InterviewStatus.Attachment:
              navigate(
                pathBuilder({ path: PrivatePaths.UPLOAD, code: interviewCode }),
              );
              break;
            case InterviewStatus.Complete:
              navigate(
                pathBuilder({
                  code: interviewCode,
                  path: PrivatePaths.THANK_YOU,
                }),
              );
              break;
          }
        })
        .catch((error: Error) => {
          handleErrorResponse(
            error,
            interviewCode,
            sessionExpired,
            displayAbbottToast,
            navigate,
          );
          setIsSaving(false);
        });
    } else {
      setActiveQuestionOrderId((activeQuestion) => activeQuestion + 1);
      setIsSaving(false);
      navigate(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.INTERVIEW,
          question: activeQuestionOrderId + 1,
        }),
      );
    }
  };

  const storeQuestionResponse = (currentQuestion: IQuestion) => {
    const selectedOption = currentQuestion.questionOptions.find((option) => {
      return answers.some((answer) => {
        return (
          answer.value === option.text &&
          Number(answer.questionId) === currentQuestion.questionId
        );
      });
    });
    const questionResponseID = currentQuestion.questionResponseId;

    const data: QuestionResponseInputData = {
      electronicInterviewID,
      questionID: currentQuestion.questionId,
      questionOptionID: selectedOption?.questionOptionId || 0,
      responseText: '',
    };

    if (questionResponseID === null || questionResponseID === undefined) {
      return createQuestionResponse(data, electronicInterviewID);
    } else {
      return updateQuestionResponse(
        questionResponseID,
        data,
        electronicInterviewID,
      );
    }
  };

  const handleNextClick = async () => {
    const currentQuestion = questions[activeQuestionOrderId - 1];
    let parentErrored = false;
    let savedQuestion = false;
    setIsSaving(true);
    await storeQuestionResponse(currentQuestion)
      .then((updatedQuestionResponseID) => {
        currentQuestion.questionResponseId = updatedQuestionResponseID;
        savedQuestion = true;
      })
      .catch((error: Error) => {
        savedQuestion = false;
        handleErrorResponse(
          error,
          interviewCode,
          sessionExpired,
          displayAbbottToast,
          navigate,
        );
        parentErrored = true;
      });

    if (showChild && currentQuestion?.childQuestion && !parentErrored) {
      savedQuestion = false;
      const childQuestion = currentQuestion.childQuestion;
      await storeQuestionResponse(childQuestion)
        .then((updatedQuestionResponseID) => {
          childQuestion.questionResponseId = updatedQuestionResponseID;
          savedQuestion = true;
        })
        .catch((error: Error) => {
          savedQuestion = false;
          handleErrorResponse(
            error,
            interviewCode,
            sessionExpired,
            displayAbbottToast,
            navigate,
          );
          savedQuestion = false;
        });
    }
    if (savedQuestion) {
      moveToNextStep();
    } else {
      setIsSaving(false);
    }
  };

  return (
    <>
      <ProgressBar
        activeQuestion={activeQuestionOrderId}
        totalQuestions={questions.length}
        barProgress={(100 / questions.length) * activeQuestionOrderId}
      />
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Grid container justifyContent="center">
          <InterviewTemplate
            question={questions[activeQuestionOrderId - 1]}
            answers={answers}
            showsChildQuestion={showChild}
            handleMultipleChoiceInput={(value, isChildQuestion) => {
              setIsLocalUpdated(true);
              handleAnswerInput(
                value,
                questions[activeQuestionOrderId - 1],
                answers,
                setAnswers,
                isChildQuestion,
              );
            }}
          />
          <NavButtons
            handleBackClick={handleBackClick}
            handleNextClick={handleNextClick}
            activeQuestion={activeQuestionOrderId}
            allowNext={!allowNext || isSaving}
            loading={isSaving}
          />
        </Grid>
      </Box>
    </>
  );
};
