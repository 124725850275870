const getBase64StringFromDataURL = (dataURL: string) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

const getFileContent = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const contentString = getBase64StringFromDataURL(reader.result as string);
      resolve(contentString);
    };
    reader.onerror = (err) => reject(err);
    reader.onabort = (err) => reject(err);

    reader.readAsDataURL(file);
  });
};

export const uploadFiles = async (files: File[], specId: number) => {
  // we need a check for edge cases to confirm that the rx_expiration for
  // uploading files has not passed by the time a user is trying to upload the file
  // this is possible if within a one hour window they have opened the app and the expiration has been exceeded

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
    body: '',
  };

  return await Promise.all<Response>(
    files.map(async (file) => {
      const fileContent = await getFileContent(file);
      const extension = file.type.split('/')[1];

      const uploadData = {
        specId: specId,
        content: fileContent,
        inputFileName: file.name,
        extention: `.${extension}`,
      };

      requestOptions.body = JSON.stringify(uploadData);

      return fetch(`/api/upload/specimen/${specId}/attachment`, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            throw new Error('Session timed out');
          }
          return response;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }),
  );
};

export const markAttachmentComplete = async (electronicInterviewId: number) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
    body: '',
  };

  return fetch(
    `/api/interview/${electronicInterviewId}/attachment-complete-status`,
    requestOptions,
  )
    .then(async (response) => {
      if (response.status === 401) {
        throw new Error('Session timed out');
      }
      if (!response.ok) {
        throw new Error('Error with attachments.');
      }

      return response.json().then((data) => {
        return data.interviewStatus;
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};
