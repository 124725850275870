import { Grid, Link, Typography } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../../context/AppContextBuilder';

export interface ReanalysisTemplateProps {
  MRONumber: string;
}

export const ReanalysisTemplate = ({ MRONumber }: ReanalysisTemplateProps) => {
  const { reanalysisHeader, reanalysisText, callReanalysis } =
    useContext(Context);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2" component="h1">
          {reanalysisHeader}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography textAlign="center">{reanalysisText}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center">{callReanalysis}</Typography>

        <Typography textAlign="center">
          <Link href={`tel:${MRONumber}`}>{MRONumber}</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
