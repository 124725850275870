import { AnswerEntry } from '../pages/Interview/Interview';
import { array, boolean, date, number, object, string } from 'yup';
import { IQuestion } from '../components/interview/question/Question';
import type { IRadioOption } from '../components/shared/custom-radio/CustomRadio';
import { InterviewTemplateProps } from '../templates/InterviewTemplate/InterviewTemplate';

// we will need changes to our handlers to account for the QuestionOption structure provided with the Question, as the eMI API will rely on the ID tied to that QuestionOption structure in updating the value
// these changes will probably be best handled when implementing our actual API calls and controllers

export interface QuestionResponseInputData {
  electronicInterviewID: number;
  questionID: number;
  questionOptionID: number;
  responseText: string;
}

export interface QuestionUpdateInputData extends QuestionResponseInputData {
  questionResponseId: number;
}

const QuestionSchema = object({
  maxResponse: number(),
  isActive: boolean().required(),
  questionId: number().required(),
  isRequired: boolean().required(),
  questionText: string().nullable(),
  questionType: string().required(),
  questionOrder: number().required(),
  childQuestionId: number().nullable(),
  questionResponseId: number().nullable(),
  specialInstructions: string().nullable(),
  questionResponseText: string().nullable(),
  questionOptions: array(
    object({
      text: string().required(),
      isActive: boolean().required(),
      noticeText: string().nullable(),
      questionOptionId: number().required(),
      isRXUploadOption: boolean().required(),
      questionResponseId: number().nullable(),
      isSelectedResponse: boolean().required(),
      rxUploadDescription: string().nullable(),
      isEndInterviewOption: boolean().required(),
      isChildQuestionOption: boolean().required(),
    }).required(),
  ).required(),
}).required();

export const InterviewDataValidationSchema = object({
  rxExpiration: date().nullable(),
  dispositionId: number().nullable(),
  interviewStatus: string(),
  specId: number().required(),
  analyteNames: array(string().required()).required(),
  electronicInterviewId: number().required(),
  mroInfo: object({
    mroName: string(),
    mroPhone: string().required(),
  }).required(),
  donorInfo: object({
    firstName: string().required(),
  }).required(),
  interviewQuestions: array(QuestionSchema).required(),
});

export const applyActiveValue = (
  answers: InterviewTemplateProps['answers'],
  question?: InterviewTemplateProps['question'],
) => {
  const foundValue = answers?.find(
    (answer) => answer.questionId === question?.questionId,
  )?.value;

  if (typeof foundValue === 'string') return foundValue;
};

export const replaceOrSetAnswers = (
  value: string,
  answers: AnswerEntry[],
  question: IQuestion,
) => {
  const newAnswers = [...answers];

  const answerToBeReplaced = newAnswers.findIndex((answerEntry) => {
    return answerEntry?.questionId === question?.questionId;
  });

  if (answerToBeReplaced === -1) {
    newAnswers.push({
      questionId: question.questionId,
      value: value,
    });
  } else {
    newAnswers.splice(answerToBeReplaced, 1, {
      questionId: question.questionId,
      value: value,
    });
  }
  return newAnswers;
};

export const handleAnswerInput = (
  value: string,
  question: IQuestion,
  answers: AnswerEntry[],
  setAnswers: React.Dispatch<React.SetStateAction<any>>,
  isChildQuestion?: boolean,
) => {
  let newAnswers = [];
  if (isChildQuestion && question.childQuestion) {
    newAnswers = replaceOrSetAnswers(value, answers, question.childQuestion);
  } else {
    newAnswers = replaceOrSetAnswers(value, answers, question);
    if (question.childQuestion) {
      let parentQuestionAnswer = newAnswers.find(
        (answer) => answer.questionId === question.questionId,
      );
      let isChildQuestionOption = question.questionOptions.find(
        (questionOption) => questionOption.text === parentQuestionAnswer?.value,
      )?.isChildQuestionOption;
      if (!isChildQuestionOption) {
        const answerToBeRemovedIndex = newAnswers.findIndex((answerEntry) => {
          return answerEntry?.questionId === question.childQuestion?.questionId;
        });
        if (answerToBeRemovedIndex !== -1) {
          newAnswers.splice(answerToBeRemovedIndex);
        }
      }
    }
  }
  setAnswers(newAnswers);
};

export const markQuestionsComplete = async (electronicInterviewId: number) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
    body: '',
  };

  return fetch(
    `/api/interview/${electronicInterviewId}/questions-complete-status`,
    requestOptions,
  )
    .then(async (response) => {
      if (response.status === 401) {
        throw new Error('Session timed out');
      }
      if (!response.ok) {
        throw new Error();
      }
      return response.json().then((data) => {
        return data;
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createQuestionResponse = (
  data: QuestionResponseInputData,
  interviewId: number,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(`/api/questionResponse/interview/${interviewId}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Session timed out');
      }
      if (!response.ok) {
        throw new Error();
      }
      return response.json().then((data) => data.questionResponseID);
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateQuestionResponse = (
  questionResponseId: number,
  data: QuestionResponseInputData,
  interviewId: number,
) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
    body: JSON.stringify({ ...data, questionResponseId: questionResponseId }),
  };
  return fetch(`/api/questionResponse/interview/${interviewId}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Session timed out');
      }
      if (!response.ok) {
        throw new Error();
      }
      return questionResponseId;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const parseQuestionOptionsTexts = (question?: IQuestion) => {
  if (question) {
    const optionsTexts: IRadioOption[] = question.questionOptions.map(
      (option) => ({ text: option.text, noticeText: option.noticeText }),
    );
    return optionsTexts;
  }
  return [];
};

export const extractStoredAnswers = (questions: IQuestion[]) => {
  const questionAnswers: AnswerEntry[] = [];
  questions.forEach((question) => {
    if (
      !!question.questionOptions.find((option) => option.isSelectedResponse)
        ?.text
    ) {
      questionAnswers.push({
        questionId: question.questionId,
        value: question.questionOptions.find(
          (option) => option.isSelectedResponse,
        )?.text,
      });
    }
    if (
      !!question.childQuestion &&
      !!question?.childQuestion.questionOptions.find(
        (option) => option.isSelectedResponse,
      )?.text
    ) {
      questionAnswers.push({
        questionId: question.childQuestion.questionId,
        value: question.childQuestion.questionOptions.find(
          (option) => option.isSelectedResponse,
        )?.text,
      });
    }
  });

  return questionAnswers;
};

export const getCurrentQuestionOrderId = (questions: IQuestion[]) => {
  return questions.find((ques, index) => {
    if (ques.questionResponseId !== null && index === questions.length - 1) {
      return ques;
    }
    return ques.questionResponseId === null;
  })?.questionOrder;
};

export const getInterviewData = async (interviewCode: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.jwt}`,
    },
  };

  return fetch(`/api/interview/${interviewCode}`, requestOptions)
    .then(async (resp) => {
      if (resp.ok) {
        const responseBody = await resp.json();
        const typedResponse = await InterviewDataValidationSchema.validate(
          responseBody,
        );
        return typedResponse;
      } else if (resp.status === 401) {
        throw new Error('Session timed out.');
      } else {
        throw new Error('An error occurred.');
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
};
