import { strings } from '../strings';
import { Canceled, LockedOut } from './verificationUtilities';
import { DisplayAbbottToastProps } from '@abbott-components/core';
import { PrivatePaths, PublicPaths } from '../routing/FionaRouteProvider';

export const displayNetworkError = (
  displayAbbottToast: (data: DisplayAbbottToastProps) => void,
  errorMessage?: string,
) => {
  let messageToDisplay = errorMessage ?? strings.saveError;
  displayAbbottToast({
    type: 'error',
    message: messageToDisplay,
    position: 'top-center',
    autoHideDuration: 8000,
  });
};

type InterviewCodePathArgs = {
  code: string;
  question?: undefined;
  path:
    | PublicPaths.FAQ
    | PublicPaths.HOME
    | PublicPaths.TERMS
    | PrivatePaths.UPLOAD
    | PrivatePaths.WELCOME
    | PublicPaths.CANCELED
    | PublicPaths.FAQ_ERROR
    | PrivatePaths.THANK_YOU
    | PrivatePaths.REANALYSIS
    | PublicPaths.VERIFICATION
    | PublicPaths.UNAUTHORIZED;
};
type InterviewQuestionPathArgs = {
  code: string;
  question: number;
  path: PrivatePaths.INTERVIEW;
};
type NoInterviewCodePathArgs = {
  code?: undefined;
  question?: undefined;
  path:
    | PublicPaths.NO_CODE_FAQ
    | PublicPaths.NO_CODE_TERMS
    | PublicPaths.NO_CODE_FAQ_ERROR
    | PublicPaths.NO_CODE_CANCELED;
};

export function pathBuilder({
  code,
  path,
  question,
}:
  | InterviewCodePathArgs
  | InterviewQuestionPathArgs
  | NoInterviewCodePathArgs) {
  if (!code) {
    return path;
  }

  const codedPath = path.replace(':interviewCode', code);
  if (question) {
    return codedPath.replace(':question', question.toString());
  }

  return codedPath;
}

export function canceledLockedOutRedirect(
  data: LockedOut | Canceled,
  interviewCode: string,
) {
  if ((data as LockedOut)?.lockedOut) {
    sessionStorage.setItem('lockedOut', 'true');
    return pathBuilder({
      code: interviewCode,
      path: PublicPaths.UNAUTHORIZED,
    });
  }

  if ((data as Canceled)?.canceled) {
    sessionStorage.setItem('canceled', 'true');
    return pathBuilder({
      code: interviewCode,
      path: PublicPaths.CANCELED,
    });
  }

  return '';
}
