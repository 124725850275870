import { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { Context } from '../../context/AppContextBuilder';

export const TermsTemplate = () => {
  const {
    contactUs,
    appOverview,
    privacyText,
    introduction,
    privacyHeader,
    useAcceptance,
    ownershipText,
    userContentText,
    ownershipHeader,
    terminationText,
    prohibitionsText,
    bindingAgreement,
    textMessageHeader,
    acceptableUseText,
    userContentHeader,
    terminationHeader,
    incorporationText,
    prohibitionsHeader,
    termsAndConditions,
    incorporationHeader,
    acceptableUseHeader,
    textMessageAgreement,
    bindingAgreementText,
    loginCredentialsText,
    indeminificationText,
    contactUsInstructions,
    indemnificationHeader,
    bindingAgreementHeader,
    authorizationStatement,
    loginCredentialsHeader,
    lawsAndRegulationsText,
    yourRepresentationsText,
    changesServiceTermsText,
    lawsAndRegulationsHeader,
    termsUnderlyingAgreement,
    yourRepresentationsHeader,
    limitationOfLiabilityText,
    changesServiceTermsHeader,
    copyrightsAndTrademarksText,
    limitationOfLiabilityHeader,
    confidentialityAndNonUseText,
    copyrightsAndTrademarksHeader,
    termsUnderlyingAgreementHeader,
    confidentialityAndNonUseHeader,
    potentialDisruptionOfServiceText,
    potentialDisruptionOfServiceHeader,
  } = useContext(Context);

  return (
    <Box textAlign="left">
      <Typography
        variant="h3"
        component="h1"
        marginBottom="1rem"
        textAlign="center"
      >
        {termsAndConditions}
      </Typography>
      <Typography variant="body1" marginBottom="1rem">
        {introduction}
      </Typography>
      <Typography variant="body1" fontWeight={700} marginBottom="1rem">
        {bindingAgreement}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography fontWeight={700} component="b">
          {`${appOverview} `}
        </Typography>
        {authorizationStatement}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography fontWeight={700} component="b">
          {`${termsUnderlyingAgreementHeader} `}
        </Typography>
        {termsUnderlyingAgreement}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography fontWeight={700} component="b">
          {`${textMessageHeader} `}
        </Typography>
        {textMessageAgreement}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${bindingAgreementHeader} `}
        </Typography>
        {bindingAgreementText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${loginCredentialsHeader} `}
        </Typography>
        {loginCredentialsText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${prohibitionsHeader} `}
        </Typography>
        {prohibitionsText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${acceptableUseHeader} `}
        </Typography>
        {acceptableUseText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${userContentHeader} `}
        </Typography>
        {userContentText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${lawsAndRegulationsHeader} `}
        </Typography>
        {lawsAndRegulationsText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${confidentialityAndNonUseHeader} `}
        </Typography>
        {confidentialityAndNonUseText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${privacyHeader} `}
        </Typography>
        {privacyText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${ownershipHeader} `}
        </Typography>
        {ownershipText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${copyrightsAndTrademarksHeader} `}
        </Typography>
        {copyrightsAndTrademarksText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${potentialDisruptionOfServiceHeader} `}
        </Typography>
        {potentialDisruptionOfServiceText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${yourRepresentationsHeader} `}
        </Typography>
        {yourRepresentationsText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${limitationOfLiabilityHeader} `}
        </Typography>
        {limitationOfLiabilityText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${indemnificationHeader} `}
        </Typography>
        {indeminificationText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${terminationHeader} `}
        </Typography>
        {terminationText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${incorporationHeader} `}
        </Typography>
        {incorporationText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${changesServiceTermsHeader} `}
        </Typography>
        {changesServiceTermsText}
      </Typography>

      <Typography variant="body1" marginBottom="1rem">
        <Typography variant="body1" fontWeight={700} component="b">
          {`${contactUs}. `}
        </Typography>
        {contactUsInstructions}
      </Typography>

      <Typography variant="body1">{useAcceptance}</Typography>
    </Box>
  );
};
