import { useContext, useEffect, useRef } from 'react';
import { useAbbottToast } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';
import { ValidInterviewData } from '../Interview/Interview';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import { WelcomeTemplate } from '../../templates/WelcomeTemplate/WelcomeTemplate';
import {
  InterviewContext,
  InterviewStatus,
} from '../../context/InterviewContext';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';

export const Welcome = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const hasRedirected = useRef(false);
  const { displayAbbottToast } = useAbbottToast();
  const {
    firstName,
    setStatus,
    setFirstName,
    analyteNames,
    interviewCode,
    setAnalyteNames,
    setMroPhoneNumber,
  } = useContext(InterviewContext);
  const { emiPrefix, welcome, sessionExpired, genericLoadError } =
    useContext(Context);

  document.title = `${emiPrefix} ${welcome}`;

  useEffect(() => {
    if (!hasRedirected.current) {
      if ((data as { timedout: boolean }).timedout) {
        hasRedirected.current = true;
        displayNetworkError(displayAbbottToast, sessionExpired);
        navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
      } else if ((data as { errorMessage: string }).errorMessage) {
        hasRedirected.current = true;
        displayNetworkError(displayAbbottToast, genericLoadError);
        navigate(
          pathBuilder({ path: PublicPaths.UNAUTHORIZED, code: interviewCode }),
        );
      }
    }
  }, [
    data,
    navigate,
    interviewCode,
    sessionExpired,
    genericLoadError,
    displayAbbottToast,
  ]);

  useEffect(() => {
    const { mroInfo, donorInfo, analyteNames, interviewStatus } =
      data as ValidInterviewData;

    if (!(data as { errorMessage: string }).errorMessage) {
      if (donorInfo.firstName) {
        setFirstName(donorInfo.firstName);
      }
      setAnalyteNames(analyteNames);
      setStatus(interviewStatus as InterviewStatus);
      if (mroInfo.mroPhone) {
        setMroPhoneNumber(
          mroInfo.mroPhone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
        );
      }
    }

    // Disabling the linter here because these are all set state functions which are identities and thus
    // do not need to be listed here as per the react docs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <WelcomeTemplate
      name={firstName}
      specimenTypes={analyteNames}
      continueClick={() => {
        navigate(
          pathBuilder({
            question: 1,
            code: interviewCode,
            path: PrivatePaths.INTERVIEW,
          }),
        );
      }}
    />
  );
};
