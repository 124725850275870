import { Box, Typography, Link } from '@mui/material';
import { useContext, useState } from 'react';
import { InterviewContext } from '../../../context/InterviewContext';
import { Context } from '../../../context/AppContextBuilder';

export const MroLink = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { mroPhoneNumber } = useContext(InterviewContext);
  const { mroLink, holdTimes, mroRepresentative } = useContext(Context);

  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        left: '50%',
        transform: 'translate(-50%, 0)',
        marginBottom: '2rem',
      }}
    >
      <Link
        variant="body1"
        sx={{ color: 'abbott.darkGray', textDecoration: 'underline' }}
        onClick={() => setIsVisible(!isVisible)}
      >
        {mroLink}
      </Link>
      {isVisible ? (
        <>
          <Typography>
            {`${mroRepresentative} `}
            <Link href={`tel:${mroPhoneNumber}`}>{mroPhoneNumber}</Link>
          </Typography>
          <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
            {holdTimes}
          </Typography>
        </>
      ) : null}
    </Box>
  );
};
