import { Context } from '../../context/AppContextBuilder';
import { Link, Grid, Typography, Box } from '@mui/material';
import { ValidInterviewData } from '../Interview/Interview';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  InputButton,
  ModalDialog,
  useAbbottToast,
  FileWithStatus,
} from '@abbott-components/core';
import { IQuestion } from '../../components/interview/question/Question';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import { UploadTemplate } from '../../templates/UploadTemplate/UploadTemplate';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';
import {
  DispositionStatus,
  InterviewContext,
  InterviewStatus,
} from '../../context/InterviewContext';
import {
  markAttachmentComplete,
  uploadFiles,
} from '../../utilities/uploadUtilities';

type FileResponse = {
  specId: number;
  content: string;
  fileName: string;
  filePath: string;
  extention: string;
  inputFileName: string;
  specimenAttachmentQueued: boolean;
};

const FILE_SIZE_LIMIT = 20480;

export const RxUpload = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const attemptedFiles = useRef<string[]>([]);
  const { displayAbbottToast } = useAbbottToast();
  const [tooBigFiles, setTooBigFiles] = useState<Set<File>>();
  const [uploads, setUploads] = useState<FileWithStatus[]>([]);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const {
    saveError,
    emiPrefix,
    uploadTitle,
    templateLabel,
    sessionExpired,
    uploadHelpTitle,
    submitButtonText,
    uploadFailedHelp,
    genericLoadError,
    uploadHelpAction,
    uploadHelpMessage,
    genericUploadError,
    uploadTechnicalRequirements,
    uploadTechnicalRequirements2,
  } = useContext(Context);
  const {
    specId,
    questions,
    setSpecId,
    setStatus,
    setLoading,
    setFirstName,
    setQuestions,
    interviewCode,
    setAnalyteNames,
    setRxExpiration,
    setDispositionId,
    setMroPhoneNumber,
    electronicInterviewID,
    setElectronicInterviewID,
  } = useContext(InterviewContext);

  if ((data as { timedout: boolean }).timedout) {
    displayNetworkError(displayAbbottToast, sessionExpired);
    navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
  } else if ((data as { errorMessage: string }).errorMessage) {
    displayNetworkError(displayAbbottToast, genericLoadError);
    navigate(
      pathBuilder({ path: PublicPaths.UNAUTHORIZED, code: interviewCode }),
    );
  }

  useEffect(() => {
    const {
      specId,
      analyteNames,
      rxExpiration,
      dispositionId,
      interviewStatus,
      interviewQuestions,
      mroInfo: { mroPhone },
      electronicInterviewId,
      donorInfo: { firstName },
    } = data as ValidInterviewData;

    setSpecId(specId);
    setFirstName(firstName);
    setAnalyteNames(analyteNames);
    setRxExpiration(new Date(rxExpiration || ''));
    setStatus(interviewStatus as InterviewStatus);
    setQuestions(interviewQuestions as IQuestion[]);
    setElectronicInterviewID(electronicInterviewId);
    setDispositionId(dispositionId as DispositionStatus);
    setLoading(false);
    setMroPhoneNumber(
      mroPhone
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
    );
    // Disabling the linter here because these are all set state functions which are identities and thus
    // do not need to be listed here as per the react docs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  document.title = `${emiPrefix} ${uploadTitle}`;

  let rxUploadInstructions: string[] = [];
  let counter = 1;
  questions.forEach((question) => {
    question.questionOptions.forEach((questionOption) => {
      if (
        questionOption.isSelectedResponse &&
        questionOption.rxUploadDescription != null
      ) {
        rxUploadInstructions.push(
          counter + '. ' + questionOption.rxUploadDescription,
        );
        counter++;
      }
    });
  });

  const filterUploads = useCallback((filesToUpload: FileWithStatus[]) => {
    return filesToUpload.filter(
      (fileToUpload) => !attemptedFiles.current.includes(fileToUpload.name),
    );
  }, []);

  const trackFile = useCallback(
    (
      fileList: FileWithStatus[],
      status: 'success' | 'failure',
      file?: FileWithStatus,
    ) => {
      if (file) {
        file.uploadStatus = status;
        fileList.push(file);
        attemptedFiles.current.push(file.name);
      }
    },
    [],
  );

  const attemptUpload = useCallback(async (): Promise<
    [boolean, FileWithStatus[]]
  > => {
    const responses = await uploadFiles(filterUploads(uploads), specId);

    let isSuccessfulUpload = true;
    const fileResponses: FileWithStatus[] = [];
    for (let i = 0; i < responses.length; i++) {
      const res = responses[i];
      // File is too large (over 20mb)
      if (res.status === 413) {
        isSuccessfulUpload = false;
        tooBigFiles?.forEach((file) => {
          if (
            !fileResponses.some(
              (responseFile) => responseFile.name === file.name,
            )
          ) {
            trackFile(fileResponses, 'failure', file);
          }
        });
      } else {
        const jsonResponse = await res.json();
        if (res.ok) {
          const jsonFileData: FileResponse = jsonResponse;
          const file: FileWithStatus | undefined = uploads.find(
            (uploadedFile) => uploadedFile.name === jsonFileData.inputFileName,
          );

          trackFile(fileResponses, 'success', file);
        } else {
          isSuccessfulUpload = false;
          const file: FileWithStatus | undefined = uploads.find(
            (uploadedFile) => uploadedFile.name === (jsonResponse as string),
          );
          trackFile(fileResponses, 'failure', file);
        }
      }
    }

    return [isSuccessfulUpload, fileResponses];
  }, [filterUploads, uploads, specId, tooBigFiles, trackFile]);

  const handleNextClick = async () => {
    setSaving(true);
    try {
      const [isSuccessfulUpload, fileResponses] = await attemptUpload();

      if (isSuccessfulUpload) {
        const newStatus = await markAttachmentComplete(electronicInterviewID);
        if (newStatus === 'Error: Session timed out') {
          displayNetworkError(displayAbbottToast, sessionExpired);
          navigate(
            pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
          );
        }
        setStatus(newStatus as InterviewStatus);
        navigate(
          pathBuilder({ path: PrivatePaths.THANK_YOU, code: interviewCode }),
        );
      } else {
        setUploads([...fileResponses]);
        throw new Error(genericUploadError);
      }
    } catch (e) {
      const error = e as Error;
      if (error.message === 'Error: Session timed out') {
        displayNetworkError(displayAbbottToast, sessionExpired);
        navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
      } else if (error.message === genericUploadError) {
        displayAbbottToast({
          type: 'error',
          message: error.message,
          position: 'top-center',
          autoHideDuration: 8000,
        });
      } else {
        displayAbbottToast({
          type: 'error',
          message: saveError,
          position: 'top-center',
          autoHideDuration: 8000,
        });
      }
      setSaving(false);
    }
  };

  const handleUploadInput = (fileData: File[]) => {
    const bigFiles: Set<File> = new Set();
    fileData.forEach((file) => {
      if (file.size > FILE_SIZE_LIMIT) {
        bigFiles.add(file);
      }
    });

    setTooBigFiles(bigFiles);
    setUploads(fileData);
  };

  let shouldDisableNext = true;
  if (uploads.length === 0) {
    shouldDisableNext = true;
  }

  if (
    uploads.some(
      (file) =>
        file.uploadStatus === undefined || file.uploadStatus === 'success',
    )
  ) {
    shouldDisableNext = false;
  }

  if (saving) {
    shouldDisableNext = true;
  }

  return (
    <Grid container>
      <ModalDialog
        title={uploadHelpTitle}
        isModalOpen={isHelpModalOpen}
        onClose={() => {
          setIsHelpModalOpen(false);
        }}
      >
        <Typography sx={{ color: 'abbott.charcoal' }}>
          {uploadHelpMessage}
          <ul>
            <li>{uploadTechnicalRequirements}</li>
            <li>{uploadTechnicalRequirements2}</li>
          </ul>
          <Box sx={{ fontStyle: 'italic' }}>{uploadHelpAction}</Box>
        </Typography>
      </ModalDialog>
      <UploadTemplate
        files={uploads}
        disabled={saving}
        label={templateLabel}
        handleUpload={handleUploadInput}
        uploadInstructions={rxUploadInstructions}
        uploadFailedElement={
          <Box sx={{ textAlign: 'center', py: 1 }}>
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setIsHelpModalOpen(true);
              }}
            >
              <Typography fontSize={14} variant="button" textTransform="none">
                {uploadFailedHelp}
              </Typography>
            </Link>
          </Box>
        }
      />
      <Grid item xs={12} justifyContent="center" display="flex">
        <InputButton
          name="submit"
          loading={saving}
          onClick={handleNextClick}
          disabled={shouldDisableNext}
        >
          {submitButtonText}
        </InputButton>
      </Grid>
    </Grid>
  );
};
