import { strings } from '../strings';
import { DisplayAbbottToastProps } from '@abbott-components/core';

import { InferType, boolean, number, object, string } from 'yup';

export type ValidateCodeResponse = { canceled: boolean } & {
  lockedOut: boolean;
} & InferType<typeof CodeValidationResponseSchema>;

export const displayBadEntryError = (
  displayAbbottToast: (data: DisplayAbbottToastProps) => void,
) => {
  displayAbbottToast({
    type: 'warning',
    message: strings.idNotFound,
    position: 'top-center',
    autoHideDuration: 8000,
  });
};

export function isTokenValid(isLockedOut: boolean) {
  const dotNetJwtRegex =
    /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_.+/=]*$/;
  const token = sessionStorage.getItem('jwt');

  if (token && dotNetJwtRegex.test(token) && !isLockedOut) {
    return true;
  }

  return false;
}

export type Canceled = { canceled: boolean };
export type LockedOut = { lockedOut: boolean };

export enum ValidateCodeStatus {
  VALID = 'Valid',
  LINK_EXPIRED = 'Link expired',
  CANCELLED = 'Cancelled',
  PENDING_REVIEW = 'PendingReview',
  COMPLETE = 'Complete',
}

export const CodeValidationResponseSchema = object({
  authenticationId: number(),
  mroPhone: string().required(),
  electronicInterviewId: number(),
  clientName: string().nullable(),
  step1FailCount: number().nullable(),
  step2FailCount: number().nullable(),
  interviewStatus: string().required(),
  termsAcknowledged: boolean().nullable(),
  completeAuthenticationFailure: boolean(),
});

export async function validateCode(
  interviewCode: string,
): Promise<
  InferType<typeof CodeValidationResponseSchema> | LockedOut | Canceled
> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(`/api/auth/interview/${interviewCode}`, requestOptions)
    .then(async (resp) => {
      const responseJSON = await resp.json();
      const typedResponse = await CodeValidationResponseSchema.validate(
        responseJSON,
      );

      const currentStatus = typedResponse.interviewStatus as ValidateCodeStatus;

      if (currentStatus === ValidateCodeStatus.LINK_EXPIRED) {
        return { lockedOut: true };
      } else if (currentStatus === ValidateCodeStatus.CANCELLED) {
        return { canceled: true };
      }

      return typedResponse;
    })
    .catch((err) => {
      return { lockedOut: true };
    });
}

export const logout = () => {
  sessionStorage.clear();
};
