import React, { useState } from 'react';
import { IQuestion } from '../components/interview/question/Question';
import { ValidateCodeStatus } from '../utilities/verificationUtilities';

export enum InterviewStatus {
  New = 'New',
  Partial = 'Partial',
  Attachment = 'Attachment',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export enum DispositionStatus {
  Negative = 1, // Overturned
  Positive = 2, // Reanalysis
  PositiveUTCD = 5, //Unable to contact donor
}

export interface IInterviewContext {
  questions: IQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<IQuestion[]>>;
  specId: number;
  setSpecId: React.Dispatch<React.SetStateAction<number>>;
  status?: InterviewStatus;
  setStatus: React.Dispatch<React.SetStateAction<InterviewStatus | undefined>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  analyteNames: string[];
  setAnalyteNames: React.Dispatch<React.SetStateAction<string[]>>;
  electronicInterviewID: number;
  setElectronicInterviewID: React.Dispatch<React.SetStateAction<number>>;
  interviewCode: string;
  dispositionId?: DispositionStatus;
  setDispositionId: React.Dispatch<
    React.SetStateAction<DispositionStatus | undefined>
  >;
  rxExpiration?: Date;
  setRxExpiration: React.Dispatch<React.SetStateAction<Date | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMroPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  mroPhoneNumber: string;
  clientName: string;
  setClientName: React.Dispatch<React.SetStateAction<string>>;
  termsAcknowledged: boolean;
  setTermsAcknowledged: React.Dispatch<React.SetStateAction<boolean>>;
  setInterviewStatus: React.Dispatch<
    React.SetStateAction<ValidateCodeStatus | undefined>
  >;
  interviewStatus: ValidateCodeStatus | undefined;
}

export const InterviewContext = React.createContext<IInterviewContext>({
  questions: [],
  setQuestions: () => [],
  specId: 0,
  setSpecId: () => 0,
  status: undefined,
  setStatus: () => undefined,
  firstName: '',
  setFirstName: () => '',
  analyteNames: [],
  setAnalyteNames: () => [],
  electronicInterviewID: 0,
  setElectronicInterviewID: () => 0,
  interviewCode: '',
  dispositionId: undefined,
  setDispositionId: () => undefined,
  rxExpiration: new Date(),
  setRxExpiration: () => undefined,
  loading: true,
  setLoading: () => undefined,
  mroPhoneNumber: '',
  setMroPhoneNumber: () => undefined,
  clientName: '',
  setClientName: () => undefined,
  termsAcknowledged: false,
  setTermsAcknowledged: () => undefined,
  setInterviewStatus: () => undefined,
  interviewStatus: undefined,
});

const code = window.location.href.split('/')[3];

export interface InterviewProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const InterviewProvider = ({ children }: InterviewProviderProps) => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [specId, setSpecId] = useState(0);
  const [status, setStatus] = useState<InterviewStatus | undefined>(undefined);
  const [firstName, setFirstName] = useState('');
  const [analyteNames, setAnalyteNames] = useState<string[]>([]);
  const [electronicInterviewID, setElectronicInterviewID] = useState(0);
  const [dispositionId, setDispositionId] = useState<
    DispositionStatus | undefined
  >(undefined);
  const [rxExpiration, setRxExpiration] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(true);
  const [mroPhoneNumber, setMroPhoneNumber] = useState<string>('(888)382-2084');
  const [clientName, setClientName] = useState<string>('');
  const [termsAcknowledged, setTermsAcknowledged] = useState<boolean>(false);
  const [interviewStatus, setInterviewStatus] = useState<
    ValidateCodeStatus | undefined
  >();

  return (
    <InterviewContext.Provider
      value={{
        firstName,
        setFirstName,
        analyteNames,
        setAnalyteNames,
        questions,
        setQuestions,
        specId,
        setSpecId,
        status,
        setStatus,
        electronicInterviewID,
        setElectronicInterviewID,
        interviewCode: code,
        dispositionId,
        setDispositionId,
        rxExpiration,
        setRxExpiration,
        loading,
        setLoading,
        mroPhoneNumber,
        setMroPhoneNumber,
        clientName,
        setClientName,
        termsAcknowledged,
        setTermsAcknowledged,
        setInterviewStatus,
        interviewStatus,
      }}
    >
      {children}
    </InterviewContext.Provider>
  );
};
