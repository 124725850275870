export const provideUTCTime = (date?: Date) => {
  if (date) {
    return new Date(date.toUTCString().slice(0, 25)).valueOf();
  }
  return new Date(new Date().toUTCString().slice(0, 25)).valueOf();
};

export const provideUploadTimeRemaining = (
  handleDeadlineMissed: () => void,
  uploadByDate?: Date,
) => {
  const now = provideUTCTime();
  if (uploadByDate) {
    const expiration = uploadByDate.valueOf();
    const msRemaining = expiration - now;
    const hoursRemaining = msRemaining / 1000 / 60 / 60;
    const roundedHoursRemaining = hoursRemaining.toFixed(0);
    const fractional = hoursRemaining.toString().split('.')[1];

    if (hoursRemaining <= 0) {
      handleDeadlineMissed();
      return;
    }

    if (Number.parseInt(roundedHoursRemaining) < 1 && hoursRemaining > 0) {
      const minutesRemaining = Math.floor(
        Number.parseFloat(`.${fractional}`) * 60,
      );
      if (minutesRemaining === 1) {
        return `${minutesRemaining} minute.`;
      }
      return `${minutesRemaining} minutes.`;
    }

    return `${roundedHoursRemaining} ${
      roundedHoursRemaining === '1' ? 'hour.' : 'hours.'
    }`;
  }
};
