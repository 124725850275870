import { useContext } from 'react';
import { EscreenLogo, Menu } from '@abbott-components/icons';
import { Context } from '../../../context/AppContextBuilder';
import { pathBuilder } from '../../../utilities/commonUtilities';
import { logout } from '../../../utilities/verificationUtilities';
import { InterviewContext } from '../../../context/InterviewContext';
import { InputButton, useResponsive } from '@abbott-components/core';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { PrivatePaths, PublicPaths } from '../../../routing/FionaRouteProvider';
import {
  Box,
  AppBar,
  SxProps,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';

interface NavBarProps {
  openMenu(): void;
}

const ButtonSX: SxProps = { minHeight: 0, minWidth: 0 };

const NavBar = ({ openMenu }: NavBarProps) => {
  const { md } = useResponsive();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logoutText, faq } = useContext(Context);
  const { interviewCode } = useContext(InterviewContext);
  const matches = useMatch(
    pathBuilder({ path: PublicPaths.FAQ, code: interviewCode }),
  );

  const hasJWT = sessionStorage.getItem('jwt');
  let homePath = pathBuilder({ path: PublicPaths.HOME, code: interviewCode });
  if (hasJWT) {
    homePath = pathBuilder({ path: PrivatePaths.WELCOME, code: interviewCode });
  }

  const shouldReplace =
    pathname ===
      pathBuilder({
        code: interviewCode,
        path: PublicPaths.FAQ,
      }) ||
    pathname ===
      pathBuilder({
        path: PublicPaths.NO_CODE_FAQ,
      });

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: '7rem',
      }}
    >
      <AppBar
        sx={{
          backgroundColor: '#008ECA',
          backgroundImage: 'url("images/header.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          '@media all': {
            minHeight: '15rem',
          },
          zIndex: 1,
          boxShadow: 'none',
          marginTop: '-1rem',
        }}
      >
        <Toolbar>
          <Box
            color="inherit"
            data-testid={'escreen-logo'}
            sx={{ flexGrow: 1, margin: md ? '0 0 0 4rem' : '0 0 0 0.5rem' }}
          >
            <Link to={homePath}>
              <EscreenLogo sx={{ color: 'white', fontSize: '7rem' }} />
            </Link>
          </Box>

          {md ? (
            <Box data-testid="non-mobile-buttons" sx={{ marginRight: '56px' }}>
              <InputButton
                name="FAQ"
                variant="text"
                sx={{ minWidth: !!sessionStorage?.jwt ? null : '0' }}
                onClick={() => {
                  navigate(
                    matches
                      ? pathBuilder({
                          path: PublicPaths.FAQ,
                          code: interviewCode,
                        })
                      : pathBuilder({ path: PublicPaths.NO_CODE_FAQ }),
                    { replace: shouldReplace },
                  );
                }}
              >
                <Typography variant="h3" color="primary.contrastText">
                  {faq}
                </Typography>
              </InputButton>
              {!!sessionStorage?.jwt && (
                <InputButton
                  variant="text"
                  name="Logout"
                  onClick={() => {
                    logout();
                    navigate(
                      pathBuilder({
                        code: interviewCode,
                        path: PublicPaths.HOME,
                      }),
                    );
                  }}
                  sx={ButtonSX}
                >
                  <Typography variant="h3" color="primary.contrastText">
                    {logoutText}
                  </Typography>
                </InputButton>
              )}
            </Box>
          ) : (
            <>
              <IconButton id="openMenu" onClick={openMenu}>
                <Menu sx={{ color: 'white', fontSize: '1.5rem' }} />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
