import { Suspense, useContext } from 'react';
import { FAQTemplate } from '../../templates/FAQTemplate';
import { Context } from '../../context/AppContextBuilder';
import { pathBuilder } from '../../utilities/commonUtilities';
import { InferType, array, number, object, string } from 'yup';
import { PublicPaths } from '../../routing/FionaRouteProvider';
import { Loading } from '../../components/shared/loading/Loading';
import { Await, Navigate, useParams, useLoaderData } from 'react-router-dom';

export type IValidFAQ = InferType<typeof FAQValidation>;

export interface IFAQ {
  faqData: IValidFAQ;
}

export const FAQValidation = object({
  faqs: array(
    object({
      id: number().required(),
      answer: string().required(),
      question: string().required(),
    }),
  ).required(),
});

export const FAQ = () => {
  const data = useLoaderData() as IFAQ;
  const { interviewCode } = useParams();
  const { emiPrefix, faq } = useContext(Context);
  document.title = `${emiPrefix} ${faq}`;

  let errorUrl;
  if (interviewCode) {
    errorUrl = pathBuilder({
      code: interviewCode,
      path: PublicPaths.FAQ_ERROR,
    });
  } else {
    errorUrl = pathBuilder({
      path: PublicPaths.NO_CODE_FAQ_ERROR,
    });
  }

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={data.faqData} errorElement={<Navigate to={errorUrl} />}>
        {(data: IFAQ['faqData']) => {
          const { faqs } = data;
          const transformedFaqs = faqs.map((faq) => ({
            label: faq.question,
            content: faq.answer,
          }));

          return <FAQTemplate faq={transformedFaqs} />;
        }}
      </Await>
    </Suspense>
  );
};
