import {
  InputSelect,
  InputTextField,
  useResponsive,
} from '@abbott-components/core';
import { Autocomplete } from '@mui/material';
import { AnswerEntry, IQuestion } from '../question/Question';
import { IRadioOption } from '../../shared/custom-radio/CustomRadio';
import { applyActiveValue } from '../../../utilities/interviewUtilities';

export interface DropDownQuestionProps {
  question: IQuestion;
  QuestionOptions: IRadioOption[];
  answers: AnswerEntry[];
  handleInput: (value: string) => void;
}
const defaultValue = '';
export function DropDown({
  question,
  QuestionOptions,
  answers,
  handleInput,
}: DropDownQuestionProps) {
  const { md } = useResponsive();
  const value = applyActiveValue(answers, question) || defaultValue;
  const label = 'Select your answer';
  const inputName = `${question?.questionId}-select-field`;
  const maxSelectOptions = 10;
  const localSX = {
    marginTop: 0,
    fontSize: md ? '1.125rem' : '0.75rem',
    '&.MuiFormControl-root': {
      '& .MuiOutlinedInput-root': { textAlign: 'start' },
    },
  };
  if (QuestionOptions.length > maxSelectOptions) {
    const options = [defaultValue];
    for (const option of QuestionOptions) {
      options.push(option.text);
    }
    return (
      <Autocomplete
        renderInput={(params) => (
          <InputTextField
            {...params}
            name={inputName}
            label={label}
            sx={localSX}
          />
        )}
        options={options}
        onChange={(_event, value) => {
          handleInput(value);
        }}
        disableClearable
        value={value}
        getOptionDisabled={(option) => option === defaultValue}
      />
    );
  } else {
    return (
      <InputSelect
        name={inputName}
        onOptionSelectionChange={(value) => {
          if (handleInput) {
            handleInput(value);
          }
        }}
        label={label}
        selectedValue={value}
        defaultValue={''}
        sx={localSX}
      >
        {QuestionOptions?.map((option) => (
          <InputSelect.Option value={option.text} key={option.text}>
            {option.text}
          </InputSelect.Option>
        ))}
      </InputSelect>
    );
  }
}
