import { useNavigate } from 'react-router-dom';
import { useAbbottToast } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';
import { useAuth, ValidateDonorData } from '../../hooks/useAuth';
import { InterviewContext } from '../../context/InterviewContext';
import { useState, ChangeEvent, useContext, useEffect } from 'react';
import { VerificationTemplate } from '../../templates/VerificationTemplate';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';
import { displayBadEntryError } from '../../utilities/verificationUtilities';
import type { IRadioOption } from '../../components/shared/custom-radio/CustomRadio';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';

const validDateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

const verifyDate = (date: Date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDay().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const dateString = `${month}/${day}/${year}`;
  return validDateRegex.test(dateString) ? date : '';
};

export const Verification = () => {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [lastName, setLastName] = useState('');
  const { displayAbbottToast } = useAbbottToast();
  const { interviewCode } = useContext(InterviewContext);
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [selectedClinic, setSelectedClinic] = useState('');
  const [dob, setDob] = useState<string | Date | null>(null);
  const [clinics, setClinics] = useState<IRadioOption[]>([]);
  const { authenticateDonorInfo, authenticateClinic } = useAuth();
  const { emiPrefix, verification, sessionExpired } = useContext(Context);

  document.title = `${emiPrefix} ${verification}`;

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [currentStep]);

  const checkButtonDisabled = () => {
    if (
      (currentStep === 1 && dob instanceof Date && lastName.length !== 0) ||
      (currentStep === 2 && selectedClinic.length >= 1)
    ) {
      return false;
    }
    return true;
  };

  const handleNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value) {
      setLastName(e.target.value);
    }
  };

  const handleDateInput = (e: string | Date | null) => {
    if (typeof e == 'string') {
      validDateRegex.test(e) && setDob(e);
    } else if (e instanceof Date) {
      setDob(verifyDate(e));
    }
  };

  const handleNextClick = async () => {
    setSaving(true);
    if (currentStep === 1) {
      authenticateDonorInfo({
        code: interviewCode,
        donorLastName: lastName,
        donorDob: dob as Date,
        collectionSite: '',
      })
        .then(async (response) => {
          if (response.status === 404) {
            displayBadEntryError(displayAbbottToast);
          } else if (response.status === 403) {
            navigate(
              pathBuilder({
                code: interviewCode,
                path: PublicPaths.UNAUTHORIZED,
              }),
            );
          } else {
            const data: ValidateDonorData = await response.json();
            const clinicOptions: IRadioOption[] = JSON.parse(data.clinics).map(
              (c: string) => ({
                text: c,
              }),
            );
            sessionStorage.setItem('token', data.token);
            setClinics(clinicOptions);
            setCurrentStep(2);
          }
        })
        .catch((err) => {
          displayNetworkError(displayAbbottToast);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      authenticateClinic({
        authInput: {
          code: interviewCode,
          donorLastName: lastName,
          donorDob: dob as Date,
          collectionSite: selectedClinic,
        },
        token: sessionStorage.token,
      })
        .then((resp) => {
          if (resp.ok) {
            const thePath = pathBuilder({
              code: interviewCode,
              path: PrivatePaths.WELCOME,
            });
            navigate(thePath);
          } else {
            navigate(
              pathBuilder({
                code: interviewCode,
                path: PublicPaths.UNAUTHORIZED,
              }),
            );
          }
        })
        .catch((error: Error) => {
          console.error(error);
          if (error.message === 'Error: Session timed out') {
            displayNetworkError(displayAbbottToast, sessionExpired);
            navigate(
              pathBuilder({
                code: interviewCode,
                path: PublicPaths.HOME,
              }),
            );
          } else {
            displayNetworkError(displayAbbottToast);
            setSaving(false);
          }
        });
    }
  };

  const handleClinicSelection = (site: string) => {
    setSelectedClinic(site);
  };

  return (
    <VerificationTemplate
      currentStep={currentStep}
      handleNameInput={handleNameInput}
      handleDateInput={handleDateInput}
      clinics={clinics}
      selectedClinic={selectedClinic}
      handleClinicSelection={handleClinicSelection}
      handleNextClick={handleNextClick}
      buttonDisabled={saving || checkButtonDisabled()}
      loading={saving}
    />
  );
};
