import {
  InputButton,
  AccordionDisplay,
  AccordionDisplayProps,
} from '@abbott-components/core';
import { Box, Grid, Typography } from '@mui/material';
import { Context } from '../../context/AppContextBuilder';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export interface FAQTemplateProps {
  /** FAQ Q/A */
  faq: AccordionDisplayProps[];
}

export const FAQTemplate = ({ faq }: FAQTemplateProps) => {
  const navigate = useNavigate();
  const { faqHeader, faqText, backButton } = useContext(Context);

  return (
    <Grid container justifyContent={'center'} spacing={3}>
      <Grid item xs={12} textAlign={'center'}>
        <Typography variant="h3" component="h1">
          {faqHeader}
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent={'center'}>
        <Box maxWidth={'sm'} margin="auto">
          <Typography textAlign={'center'}>{faqText}</Typography>
        </Box>
      </Grid>
      <Grid item maxWidth="md">
        {faq?.map((questionResponse: AccordionDisplayProps) => {
          return (
            <AccordionDisplay
              key={questionResponse.label}
              label={questionResponse.label}
              content={questionResponse.content}
            />
          );
        })}

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <InputButton name="back" onClick={() => navigate(-1)}>
            {backButton}
          </InputButton>
        </Box>
      </Grid>
    </Grid>
  );
};
