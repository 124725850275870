import { useContext } from 'react';
import { Show } from '@abbott-components/core';
import { Grid, Link, Typography } from '@mui/material';
import { Context } from '../../context/AppContextBuilder';

export type CancelledInterviewTemplateProps = {
  /** MRO Phone number to display */
  phone: string;
  faqError?: boolean;
};

export const CancelledInterviewTemplate = ({
  phone,
  faqError = false,
}: CancelledInterviewTemplateProps) => {
  const {
    pleaseWait,
    callMROText,
    faqErrorText,
    genericErrorText,
    hoursOfOperationText,
    hoursOfOperationHeading,
  } = useContext(Context);

  return (
    <Grid container justifyContent="center" textAlign="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="h1" pb="1rem" pt="1rem">
          {faqError ? faqErrorText : genericErrorText}
        </Typography>
      </Grid>
      <Show when={phone} fallback={null}>
        <Grid item xs={12}>
          {faqError && (
            <Typography variant="subtitle2" component="h2">
              {pleaseWait}
            </Typography>
          )}
          <Typography variant="subtitle2" component="h2">
            {callMROText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" pb="1.75rem">
            <Link color="inherit" href={`tel:${phone}`}>
              {phone}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="h3">
            {hoursOfOperationHeading}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="h3">
            {hoursOfOperationText}
          </Typography>
        </Grid>
      </Show>
    </Grid>
  );
};
