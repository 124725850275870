import React, { useContext, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Context } from '../../context/AppContextBuilder';
import { pathBuilder } from '../../utilities/commonUtilities';
import { PrivatePaths } from '../../routing/FionaRouteProvider';
import { InterviewContext } from '../../context/InterviewContext';
import { FileUpload, useResponsive } from '@abbott-components/core';
import { Box, Link, Typography, SxProps, Grid } from '@mui/material';
import {
  CalendarUnfilled,
  DonorUnfilled,
  OneTwoThree,
  Medicine,
} from '@abbott-components/icons';

const provideFileUploadSx = (md: boolean, fileCount?: number) => {
  if (fileCount && fileCount > 0) {
    return {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '1rem',
      '& .MuiGrid-root': md
        ? {
            maxWidth: '46rem',
            '& .MuiPaper-root': {
              width: '8rem',
              height: '8rem',
            },
            '&.MuiGrid-root': {
              '&.MuiGrid-item': {
                margin: '0 0 2rem',
              },
            },
          }
        : {},
      '& .MuiButtonBase-root': md
        ? {
            '&.MuiButton-root': {
              '&.MuiButtonBase-root': {
                '&.MuiButton-root': {
                  color: 'abbott.darkGray',
                  minHeight: '3.25rem',
                  minWidth: '10rem',
                  fontSize: '1.25rem',
                },
              },
            },
          }
        : { '& .MuiSvgIcon-root': { color: 'abbott.darkGray' } },
    } as SxProps;
  }

  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& .MuiButtonBase-root': md
      ? {
          '&.MuiButton-root': {
            '&.MuiButtonBase-root': {
              '&.MuiButton-root': {
                minHeight: '10rem',
                minWidth: '18rem',
              },
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: '5rem',
            color: 'abbott.darkGray',
          },
          '& .MuiTypography-root': {
            fontSize: '1.5rem',
            color: 'abbott.darkGray',
          },
        }
      : {
          '& .MuiSvgIcon-root': {
            color: 'abbott.darkGray',
          },
          '& .MuiTypography-root': {
            color: 'abbott.darkGray',
          },
        },
  } as SxProps;
};

export interface UploadTemplateProps {
  label: string;
  files?: File[];
  disabled: boolean;
  uploadInstructions: string[];
  uploadFailedElement: React.ReactNode;
  handleUpload: (
    fileData: File[],
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const IconSxProps: SxProps = {
  color: 'abbott.darkGray',
  opacity: 0.8,
};

export const UploadTemplate = ({
  files,
  label,
  disabled,
  handleUpload,
  uploadInstructions,
  uploadFailedElement,
}: UploadTemplateProps) => {
  const { md } = useResponsive();
  const { interviewCode } = useContext(InterviewContext);
  const {
    medName,
    yourName,
    rxCriteria,
    dateFilled,
    uploadSkip,
    uploadButtonLabel,
    prescriptionNumber,
    uploadImagesCallout,
    prescriptionNotAvailable,
  } = useContext(Context);

  const ReactRouterLink = useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
        (props, ref) => (
          <RouterLink
            ref={ref}
            to={pathBuilder({
              code: interviewCode,
              path: PrivatePaths.THANK_YOU,
            })}
            {...props}
          />
        ),
      ),
    [interviewCode],
  );

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            marginBottom: '2rem',
            margin: '0 auto 1rem',
          }}
        >
          <Typography variant={md ? 'h1' : 'h3'}>{label}</Typography>
        </Box>
      </Grid>
      <Grid
        container
        sx={
          md
            ? { margin: '0 auto 2rem' }
            : { margin: '0 auto 2rem', maxWidth: '18rem' }
        }
        justifyContent={'center'}
      >
        <Box
          padding={2}
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'block',
            backgroundColor: '#F5F5F5',
            borderRadius: 3,
            border: 1,
            borderColor: '#898B8D2E',
          }}
        >
          <Grid item xs={12} marginBottom={2}>
            <Typography
              variant={md ? 'h3' : 'body2'}
              textAlign={'center'}
              fontWeight={'bold'}
              component="h2"
            >
              {uploadImagesCallout}
            </Typography>
          </Grid>
          {uploadInstructions.map(function (data) {
            return (
              <Grid item xs={12} marginBottom={1} key={data}>
                <Typography
                  variant={md ? 'h5' : 'body2'}
                  component="p"
                  textAlign={'left'}
                  fontWeight={'regular'}
                >
                  {data}
                </Typography>
              </Grid>
            );
          })}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant={md ? 'h3' : 'body2'}
          textAlign={'center'}
          fontWeight={'bold'}
          component="h2"
        >
          {rxCriteria}
        </Typography>
      </Grid>
      <Grid
        container
        sx={
          md
            ? { margin: '0 auto 2rem' }
            : { margin: '0 auto 2rem', maxWidth: '18rem' }
        }
        rowSpacing={4}
      >
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DonorUnfilled
              sx={IconSxProps}
              fontSize={md ? 'large' : 'medium'}
            />
            <Typography
              variant={md ? 'h4' : 'body2'}
              component="h3"
              sx={{ wordSpacing: md ? '99rem' : '' }}
            >
              {yourName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CalendarUnfilled
              sx={IconSxProps}
              fontSize={md ? 'large' : 'medium'}
            />
            <Typography
              variant={md ? 'h4' : 'body2'}
              component="h3"
              sx={{ wordSpacing: md ? '99rem' : '' }}
            >
              {dateFilled}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <OneTwoThree sx={IconSxProps} fontSize={md ? 'large' : 'medium'} />
            <Typography
              variant={md ? 'h4' : 'body2'}
              component="h3"
              sx={{ wordSpacing: md ? '99rem' : '' }}
            >
              {prescriptionNumber}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Medicine sx={IconSxProps} fontSize={md ? 'large' : 'medium'} />
            <Typography
              variant={md ? 'h4' : 'body2'}
              component="h3"
              sx={{ wordSpacing: md ? '99rem' : '' }}
            >
              {medName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={provideFileUploadSx(md, files?.length)}>
          <FileUpload
            value={files}
            disabled={disabled}
            onUploadChange={handleUpload}
            errorToastPosition="top-center"
            primaryLabel={uploadButtonLabel}
            addButtonLabel="Add more photos"
            failureElement={uploadFailedElement}
            fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            textAlign: 'center',
            display: 'block',
            marginBottom: '2rem',
          }}
        >
          <Typography>{prescriptionNotAvailable}</Typography>
          <Link
            variant="body2"
            color="primary.main"
            component={ReactRouterLink}
            sx={{ textDecoration: 'underline' }}
          >
            {uploadSkip}
          </Link>
        </Box>
      </Grid>
    </>
  );
};
