import { useContext, useEffect } from 'react';
import { useAbbottToast } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';
import { ValidInterviewData } from '../Interview/Interview';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { PublicPaths } from '../../routing/FionaRouteProvider';
import { InterviewContext } from '../../context/InterviewContext';
import { ReanalysisTemplate } from '../../templates/ReanalysisTemplate';
import {
  displayNetworkError,
  pathBuilder,
} from '../../utilities/commonUtilities';

export const Reanalysis = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const { displayAbbottToast } = useAbbottToast();
  const { reanalysis, sessionExpired, emiPrefix, genericLoadError } =
    useContext(Context);
  const { mroPhoneNumber, interviewCode, setMroPhoneNumber } =
    useContext(InterviewContext);

  document.title = `${emiPrefix} ${reanalysis}`;

  if ((data as { timedout: boolean }).timedout) {
    displayNetworkError(displayAbbottToast, sessionExpired);
    navigate(pathBuilder({ path: PublicPaths.HOME, code: interviewCode }));
  } else if ((data as { errorMessage: string }).errorMessage) {
    displayNetworkError(displayAbbottToast, genericLoadError);
    navigate(
      pathBuilder({ path: PublicPaths.UNAUTHORIZED, code: interviewCode }),
    );
  }

  useEffect(() => {
    const {
      mroInfo: { mroPhone },
    } = data as ValidInterviewData;

    setMroPhoneNumber(
      mroPhone
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3'),
    );
    // Disabling the linter here because these are all set state functions which are identities and thus
    // do not need to be listed here as per the react docs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <ReanalysisTemplate MRONumber={mroPhoneNumber} />;
};
