import { pathBuilder } from '../../utilities/commonUtilities';
import { InterviewStatus } from '../../context/InterviewContext';
import { isTokenValid } from '../../utilities/verificationUtilities';
import { getInterviewData } from '../../utilities/interviewUtilities';
import { LoaderFunctionArgs, json, redirect } from 'react-router-dom';
import { PrivatePaths, PublicPaths } from '../../routing/FionaRouteProvider';

export async function interviewLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;
  if (!interviewCode) {
    return redirect(pathBuilder({ path: PublicPaths.NO_CODE_CANCELED }));
  }

  const lockedOut = sessionStorage.getItem('lockedOut') === 'true';
  if (!isTokenValid(lockedOut)) {
    return redirect(
      pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
    );
  }

  let interviewData;
  try {
    interviewData = await getInterviewData(interviewCode);

    // Status based routing
    if (interviewData.interviewStatus === InterviewStatus.Attachment) {
      return redirect(
        pathBuilder({ path: PrivatePaths.UPLOAD, code: interviewCode }),
      );
    }

    if (interviewData.interviewStatus === InterviewStatus.Complete) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.THANK_YOU,
        }),
      );
    }
  } catch (e) {
    const error = e as Error;
    if (error.message === 'Error: Session timed out.') {
      return json({ errorMessage: error.message, timedout: true });
    }
    return json({ errorMessage: error.message });
  }

  return json(interviewData);
}
