import { InferType } from 'yup';
import { FAQValidation } from './FAQ';
import { pathBuilder } from '../../utilities/commonUtilities';
import { PublicPaths } from '../../routing/FionaRouteProvider';
import { defer, redirect, LoaderFunctionArgs } from 'react-router-dom';

export async function faqLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;

  let faqData: Promise<InferType<typeof FAQValidation>>;
  try {
    faqData = fetch('/api/FAQ/faqs')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('There was an error.');
        }
      })
      .then((jsonData) => {
        return FAQValidation.validate(jsonData);
      });
  } catch {
    if (interviewCode) {
      return redirect(
        pathBuilder({ path: PublicPaths.FAQ_ERROR, code: interviewCode }),
      );
    }

    return redirect(pathBuilder({ path: PublicPaths.NO_CODE_FAQ }));
  }
  return defer({ faqData });
}
