import { useEffect, useState } from 'react';
import { AnswerEntry } from '../pages/Interview/Interview';
import { IQuestion } from '../components/interview/question/Question';

// we can add additional evaluations here for if an answer should end an interview
export const useInterview = (
  answers?: AnswerEntry[],
  currentQuestionIndex?: number,
  questions?: IQuestion[],
  uploads?: File[],
) => {
  const [allowNext, setAllowNext] = useState<boolean>(false);
  const [showChild, setShowChild] = useState<boolean>(false);
  let currentQuestion: IQuestion | undefined = undefined;
  if (
    questions &&
    typeof currentQuestionIndex !== 'undefined' &&
    currentQuestionIndex >= 0
  ) {
    currentQuestion = questions[currentQuestionIndex];
  }

  useEffect(() => {
    if (currentQuestion) {
      let shouldAppear: boolean | undefined = false;

      const currentQuestionAnswer = answers?.find(
        (answer) => answer?.questionId === currentQuestion?.questionId,
      );

      if (currentQuestionAnswer) {
        shouldAppear = currentQuestion?.questionOptions.some(
          (option) =>
            option.text === currentQuestionAnswer.value &&
            option?.isChildQuestionOption,
        );
      }

      setShowChild(shouldAppear || false);
    }
  }, [
    answers,
    currentQuestion,
    currentQuestion?.questionId,
    currentQuestion?.questionOptions,
  ]);

  useEffect(() => {
    if (currentQuestion) {
      const mainQuestionAnswered = answers?.some(
        (answer) => answer.questionId === currentQuestion?.questionId,
      );
      if (showChild) {
        const childAnswered = answers?.some(
          (answer) =>
            answer.questionId === currentQuestion?.childQuestion?.questionId,
        );
        setAllowNext((mainQuestionAnswered && childAnswered) || false);
      } else {
        setAllowNext(mainQuestionAnswered || false);
      }
    }
  }, [
    answers,
    uploads,
    showChild,
    currentQuestion,
    currentQuestion?.questionId,
    currentQuestion?.childQuestion?.questionId,
  ]);

  return {
    showChild,
    allowNext,
  };
};
