import { Suspense, useContext, useEffect } from 'react';
import { CanceledInterview } from '../CanceledInterview';
import { Context } from '../../context/AppContextBuilder';
import { PublicPaths } from '../../routing/FionaRouteProvider';
import { InterviewContext } from '../../context/InterviewContext';
import { Loading } from '../../components/shared/loading/Loading';
import HomeTemplate from '../../templates/HomeTemplate/HomeTemplate';
import {
  pathBuilder,
  canceledLockedOutRedirect,
} from '../../utilities/commonUtilities';
import {
  Canceled,
  LockedOut,
  ValidateCodeStatus,
  ValidateCodeResponse,
  CodeValidationResponseSchema,
} from '../../utilities/verificationUtilities';
import { Await, Navigate, useNavigate, useLoaderData } from 'react-router-dom';
import { InferType } from 'yup';

interface IHomeDefer {
  validateCodePromise: Promise<ValidateCodeResponse>;
}

interface AwaitedResponse {
  data: ValidateCodeResponse;
}

export type IValidHome = InferType<typeof CodeValidationResponseSchema>;

const HomeTemplateRenderer = ({ data }: AwaitedResponse) => {
  const navigate = useNavigate();
  const {
    interviewCode,
    setClientName,
    setMroPhoneNumber,
    setInterviewStatus,
    setTermsAcknowledged,
  } = useContext(InterviewContext);

  useEffect(() => {
    const { interviewStatus, clientName, mroPhone, termsAcknowledged } =
      data as AwaitedResponse['data'];
    setClientName(clientName ?? '');
    setInterviewStatus(interviewStatus as ValidateCodeStatus);
    setTermsAcknowledged(termsAcknowledged ?? false);
    if (mroPhone) {
      setMroPhoneNumber(
        mroPhone!
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3') || '(888)382-2084',
      );
    }
    // Disabling the linter here because all of the "set" functions are state setters
    // and identities and thus don't need to be here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isErrorPath = canceledLockedOutRedirect(
    data as Canceled | LockedOut,
    interviewCode,
  );
  if (isErrorPath.length > 0) {
    return <Navigate to={isErrorPath} />;
  }

  return (
    <HomeTemplate
      clientName={(data as AwaitedResponse['data']).clientName ?? ''}
      nextStep={() => {
        navigate(
          pathBuilder({
            code: interviewCode,
            path: PublicPaths.VERIFICATION,
          }),
        );
      }}
    />
  );
};

export const Home = () => {
  const data = useLoaderData() as IHomeDefer;
  const { emiPrefix, home } = useContext(Context);

  document.title = `${emiPrefix} ${home}`;

  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={data.validateCodePromise}
        errorElement={<CanceledInterview />}
      >
        {(data: Awaited<IHomeDefer['validateCodePromise']>) => (
          <HomeTemplateRenderer data={data} />
        )}
      </Await>
    </Suspense>
  );
};
