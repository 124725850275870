import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { InputButton } from '@abbott-components/core';
import { Context } from '../../context/AppContextBuilder';

export interface ThankYouReanalysisProps {
  name: string;
  handleReanalysis: () => void;
}

export const ThankYouReanalysis = ({
  name,
  handleReanalysis,
}: ThankYouReanalysisProps) => {
  const {
    nameTemplate,
    reviewedText1,
    reviewedText2,
    reviewedText3,
    reanalysisOptionText,
    orderReanalysisButtonText,
  } = useContext(Context);

  return (
    <Grid container spacing={3} justifyContent={'center'} zIndex={4}>
      <Grid item sm={7}>
        <Typography variant="h1" align="center">
          {nameTemplate(name)}
        </Typography>
      </Grid>
      <Grid item sm={7}>
        <Typography
          align="center"
          variant="h5"
          fontWeight="normal"
          textTransform="none"
          component="h2"
        >
          {reviewedText1}
          <b> {reviewedText2} </b>
          {reviewedText3}
        </Typography>
      </Grid>
      <Grid item sm={7}>
        <Typography
          align="center"
          variant="h5"
          fontWeight="normal"
          textTransform="none"
          component="h2"
        >
          {reanalysisOptionText}
        </Typography>
      </Grid>
      <Grid
        item
        sm={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <InputButton
          name={'OrderReanalysis'}
          variant="text"
          onClick={handleReanalysis}
          sx={{ textDecoration: 'underline' }}
        >
          {orderReanalysisButtonText}
        </InputButton>
      </Grid>
    </Grid>
  );
};
