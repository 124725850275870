import { LoaderFunctionArgs, defer } from 'react-router-dom';
import { validateCode } from '../../utilities/verificationUtilities';

export async function homeLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;
  if (!interviewCode) {
    throw new Error('Invalid code.');
  }

  return defer({ validateCodePromise: validateCode(interviewCode) });
}
