import { useContext } from 'react';
import { Box, Avatar } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useResponsive } from '@abbott-components/core';
import { AppPaths } from '../../../routing/FionaRouteProvider';
import { InterviewContext } from '../../../context/InterviewContext';

interface DecorationProps {
  location?: AppPaths;
}

export type DecorationTypes =
  | 'character-sitting'
  | 'character-standing'
  | 'avatar'
  | 'progress-bar'
  | 'none';

const AvatarSx: SxProps = {
  width: 83,
  height: 83,
  background: 'white',
  boxShadow: '0px 5px 10px #00000024',
  position: 'absolute',
  left: '50%',
  top: 62,
  transform: 'translate(-50%, 0)',
  zIndex: 99,
};

const ImageWrapperSx: SxProps = {
  height: 500,
  position: 'relative',
  marginBottom: '-500px',
  zIndex: 4,
  float: 'right',
  top: '-60px',
  img: {
    height: 'inherit',
  },
};

const lengthOfGuid = 36;

export const Decoration = ({ location }: DecorationProps) => {
  const { xl, md } = useResponsive();
  const { interviewCode } = useContext(InterviewContext);

  const chairSubStrings = [
    '/verification',
    '/welcome',
    '/reanalysis',
    '/canceled',
    '/unauthorized',
    '/',
  ];

  if ((location as string).toLowerCase().includes('/thank-you')) {
    if (!md) {
      return (
        <Avatar
          src="../../images/avatar.webp"
          alt="virtual assistant portrait"
          sx={AvatarSx}
        />
      );
    }
    return (
      <Box
        sx={{
          ...ImageWrapperSx,
          height: '28rem',
        }}
      >
        <img
          src="../../images/standing.webp"
          alt="virtual assistant standing"
        />
      </Box>
    );
  } else if (
    chairSubStrings.some((str) => location?.toLowerCase()?.includes(str)) ||
    (location === `/${interviewCode}` && interviewCode.length === lengthOfGuid)
  ) {
    if (xl) {
      return (
        <Box sx={{ ...ImageWrapperSx, right: '-23rem' }}>
          <img
            src="../../images/sitting.webp"
            alt="virtual assistant in chair"
          />
        </Box>
      );
    } else {
      return (
        <Avatar
          src="../../images/avatar.webp"
          alt="virtual assistant portrait"
          sx={AvatarSx}
        />
      );
    }
  }

  return null;
};
