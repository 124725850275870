import { pathBuilder } from '../../utilities/commonUtilities';
import {
  DispositionStatus,
  InterviewStatus,
} from '../../context/InterviewContext';
import { isTokenValid } from '../../utilities/verificationUtilities';
import { LoaderFunctionArgs, redirect, json } from 'react-router-dom';
import { IQuestion } from '../../components/interview/question/Question';
import { PublicPaths, PrivatePaths } from '../../routing/FionaRouteProvider';
import {
  getInterviewData,
  getCurrentQuestionOrderId,
} from '../../utilities/interviewUtilities';

export async function welcomeLoader({ params }: LoaderFunctionArgs) {
  const { interviewCode } = params;
  let interviewData;
  if (!interviewCode) {
    return redirect(pathBuilder({ path: PublicPaths.NO_CODE_CANCELED }));
  }

  const lockedOut = sessionStorage.getItem('lockedOut') === 'true';
  if (!isTokenValid(lockedOut)) {
    return redirect(
      pathBuilder({ path: PublicPaths.HOME, code: interviewCode }),
    );
  }

  try {
    interviewData = await getInterviewData(interviewCode);
    // Status based logic
    if (interviewData.interviewStatus === InterviewStatus.Partial) {
      const questionOrderToRedirectTo = getCurrentQuestionOrderId(
        interviewData.interviewQuestions as IQuestion[],
      );
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.INTERVIEW,
          question: questionOrderToRedirectTo ?? 1,
        }),
      );
    }

    if (interviewData.dispositionId === DispositionStatus.Negative) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.THANK_YOU,
        }),
      );
    }

    if (
      interviewData.dispositionId === DispositionStatus.Positive ||
      interviewData.dispositionId === DispositionStatus.PositiveUTCD
    ) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.THANK_YOU,
        }),
      );
    }

    if (interviewData.interviewStatus === InterviewStatus.Attachment) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.UPLOAD,
        }),
      );
    }

    if (interviewData.interviewStatus === InterviewStatus.Complete) {
      return redirect(
        pathBuilder({
          code: interviewCode,
          path: PrivatePaths.THANK_YOU,
        }),
      );
    }
  } catch (e) {
    const error = e as Error;
    if (error.message === 'Error: Session timed out.') {
      return json({ errorMessage: error.message, timedout: true });
    }
    return json({ errorMessage: error.message });
  }

  return json(interviewData);
}
